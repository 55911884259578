import { Injectable } from '@angular/core';
import * as Player from "@vimeo/player/dist/player.js";
import Vimeo from "@vimeo/player";
import { interval } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {

  private accessToken = '0e88c9e8b5e8d19ac76be10f957f3343';

  currentVideo: Player = null;

  paused = true;

  videoLink = null;

  duration  = 0;

  videoUpdate = 0;

  videoId = null;

  constructor(private httpClient: HttpClient, private loading: LoadingService) { }

  async setVideo(videoLink: string){
    this.videoLink = videoLink;
    var options = {
      url: videoLink,
      loop: false,
      //autoplay: false,
      background: true,
      responsive: true,
      muted: true
    }
    setTimeout(() => {
      let iframe = document.querySelector('#video-player-container');
      this.currentVideo = new Player('video-player-container', options);
      this.getDuration().then((val) => {
        this.duration = val;
      });
      this.pauseVideo();
      this.videoId =  this.videoLink.substr(31, (this.videoLink.length-1));
    },500);
  }

  async loadVideo(link: string){
    this.videoLink = link
    console.log(this.videoLink);
    if(this.currentVideo != null){
      window.location.reload();
      this.duration = 0;
      this.videoId =  this.videoLink.substr(31, (this.videoLink.length-1));
      this.currentVideo.loadVideo(this.videoId).then(function(){
        //this.pauseVideo();
      });
      this.videoUpdate = 0;
      //this.pauseVideo();
    }else{
      this.setVideo(this.videoLink);
    }
    this.getDuration().then((val) => {
      this.duration = val;
    });
    setTimeout(() => {
      this.loading.setLoadingScreen(false);
    },2000)
    //this.pauseVideo();
  }

  async removeVideo(){
    if(this.currentVideo != null){
      await this.currentVideo.unload().then(() => {
        window.location.reload();
      });
      this.currentVideo = null;
      this.paused = true;

      this.videoLink = null;

      this.duration  = 0;

      this.videoUpdate = 0;

      this.videoId = null;
    }
  }

  playVideo(){
    if(this.videoLink != null){
      this.currentVideo.play().then(function(){
        console.log("playd");
      }).catch(function(error){
        console.log(error);
      });
      this.paused = false;

      var count = interval(50);

      let countSub = count.subscribe(() => {
        this.getDepthLevel().then((val) => {
          this.videoUpdate = val;
        });

        if(this.paused){
          countSub.unsubscribe();
        }
      })
    }
  }

  pauseVideo(){
    if(this.videoLink != null){
      this.currentVideo.pause().then(function(){
        console.log("paused");
      }).catch(function(error){
        console.log(error);
      });
      this.paused = true;
    }
  }

  getPaused(){
    return this.paused;
  }

  getVideoLink(){
    return this.videoLink;
  }

  async getCurrentTime(){
    if(this.videoLink != null){
      return this.currentVideo.getCurrentTime().then(seconds => {
        return seconds;
      }).catch(function(error){
        console.log(error);
      });
    }
    return 0;
  }

  async getDuration(){
    if(this.videoLink != null){
      return this.currentVideo.getDuration().then(duration => {
        return duration;
      }).catch(function(error){
        console.log(error);
      });
    }
    return 0;
  }

  getVideoUpdate(){
    return this.videoUpdate;
  }

  setVideoUpdate(videoUpdate){
    this.videoUpdate = videoUpdate;
  }

  async getDepthLevel(){
    //GetTimeOfCurrentVideo
    let seconds = 0;
    await this.getCurrentTime().then((val) => {
      seconds = val;
    });
    //return the pixels from the top accordingly 100%
    let returnPixel = 100 * (seconds/this.duration);
    return returnPixel + 0;
  }

  changeVideoTime(timestamp){
    if(this.currentVideo != null){
      this.currentVideo.setCurrentTime(timestamp).then((val) => {
        console.log(val);
      });
    }
  }

  async createThumbnail(){
    let headers = new HttpHeaders({
      'Authorization': 'bearer ' + this.accessToken,
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.vimeo.*+json;version=3.4'
    });

    let seconds = 0;
    await this.getCurrentTime().then((val) => {
      seconds = val;
    });

    let body = {
      "time": seconds,
      "active": false
    };

    return this.httpClient.post('https://api.vimeo.com/videos/' + this.videoId + '/pictures', body, {headers: headers}).toPromise();
  }
}
