import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LoadingService } from 'src/app/loading.service';
import { VimeoUploadService } from 'src/app/vimeo-upload.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { VimeoService } from 'src/app/vimeo.service';
import {AngularFireStorage} from '@angular/fire/compat/storage'
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from 'src/app/service/header.service';


@Component({
  selector: 'app-newcase',
  templateUrl: './newcase.component.html',
  styleUrls: ['./newcase.component.scss']
})
export class NewcaseComponent implements OnInit {

  @ViewChild("videoUpdate") videoUpdate: ElementRef;

  initialVesselSave   = false;

  vessel          = null;
  port                = null;
  caseDate            = null;

  vesselLength        = 0;
  inspectionDepth     = 0;

  vesselType          = null;

  content             = "details";
  activeButton        = "btn1"

  step                = 1;

  starboard           = true;

  currentStrip        = 1;

  videoUploadOpen          = false;

  fileToUpload: File       = null;

  videoLink                = this.vimeoUpload.getVideoLink();

  strips                   = [];
  portSelectedStrips       = [];
  starboardSelectedStrips  = [];

  shipArea                = [];

  stripsDisabled           = true;

  vesselDb                     = null;
  inspectionPointCollection    = null

  stripsId               = [];
  id                     = null;

  levelDepth             = [];

  currentVesselSection          = null;

  photoLink             = null;
  photo                 = "./../../../assets/images/vessel-photo.png";

  foulingRatings        = [0, 0, 0, 0];

  screenShotActive      = false;

  mouseDown            = false;

  currentScreenshotId  = null;
  currentFoulingId      = null;

  screenShotId        = [];

  foulingColours      = [];

  screenshots         = [];

  stripDetail         = [];

  damaged             = false;
  damagedMarkers      = [];

  selectedIcons       = [];

  draftInput  = 0;
  depthInput = 0;

  draftNumbers = [];
  depthNumbers = [];

  stripIcons = [];

  screenshotToast: number;

  disableScreenshot: boolean = false;

  countriesCities = null;

  countries = [];

  countrySelected = null;
  citySelected = null;

  countrySelectedArray = null;

  visibility = null
  purpose = null;
  inspector = null;
  inspectorsSelected = [];
  coatingVert = null;
  coatingFlat = null;
  coatingBoot = null;

  coatings = [];
  inspectors = [];
  ports = [];
  visibilities = [];
  purposes = [];
  vessels = [];
  portSearch: boolean = false;
  portSearching: boolean = false;

  weathers = [];
  weather = null;
  weatherSelected = [];

  private element: HTMLElement;


  constructor(private loadingService: LoadingService,
    public vimeoUpload: VimeoUploadService,
    private db: AngularFirestore,
    public vimeo: VimeoService,
    private afStorage: AngularFireStorage,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
    private headerService: HeaderService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "inspection";
    this.loadingService.setLoadingScreen(false);
    this.strips = Array(32).fill(1,0,32).map((x,i)=>i); // [0,1,2,3,4]
    this.levelDepth = Array(11).fill(1,0,11).map((x,i)=>i);
    this.setupStripDetail();
    this.http.get('../../assets/countries.json').subscribe((countries: any )=> {
      this.countriesCities = countries.data;
      console.log(this.countriesCities)
    });
    this.db.collection('countries').ref.orderBy('country').get().then(countries => {
      if(!countries.empty){
        countries.forEach(country => {
          this.countries.push(country.data());
        })
      }
    })
    this.db.collection('coating').ref.orderBy('coating').get().then(coatings => {
      if(!coatings.empty){
        coatings.forEach(coating => {
          this.coatings.push(coating.data());
        })
      }
    })
    this.db.collection('inspector').ref.orderBy('name').get().then(inspectors => {
      if(!inspectors.empty){
        inspectors.forEach(inspector => {
          this.inspectors.push(inspector.data());
        })
      }
    })
    this.db.collection('waterVisibility').ref.orderBy('visibility').get().then(visibilities => {
      if(!visibilities.empty){
        visibilities.forEach(visibility => {
          this.visibilities.push(visibility.data());
        })
      }
    })
    this.db.collection('inspectionPurpose').ref.orderBy('purpose').get().then(purposes => {
      if(!purposes.empty){
        purposes.forEach(purpose => {
          this.purposes.push(purpose.data());
        })
      }
    })
    this.db.collection('vessel').ref.orderBy('imo').get().then(vessels => {
      if(!vessels.empty){
        vessels.forEach(vessel => {
          this.vessels.push(vessel.data());
        })
        console.log(this.vessels)
      }
    })
    this.db.collection('weather').ref.orderBy('weather').get().then(weathers => {
      if(!weathers.empty){
        weathers.forEach(weather => {
          this.weathers.push(weather.data());
        })
      }
    })
  }

  ngAfterViewInit(): void{
    this.element = this.videoUpdate.nativeElement as HTMLElement;
    this.initDrag();
  }

  async getPorts(){
    if(this.countrySelected){
      this.portSearching = true;
      await this.db.collection('port').ref.where('ccode', '==', this.countrySelectedArray.ccode).orderBy('name', 'asc').get().then(ports => {
        if(!ports.empty){
          ports.forEach(port => {
            this.ports.push(port.data());
          })
        }
      });
      this.portSearch = true;
      this.portSearching = false;
    }
  }

  selectCountry(countrySelected){
    this.countrySelectedArray = this.countries.find(x => x.country == countrySelected);
    this.countrySelected = countrySelected;
    this.portSearch = false;
  }

  selectCity(citySelected){
    this.citySelected = citySelected;
  }

  selectVessel(vessel){
    this.vessel = vessel;
  }

  selectPort(port){
    this.port = port;
  }

  selectPurpose(purpose){
    this.purpose = purpose;
  }

  selectInspector(inspector){
    this.inspector = inspector;
  }

  addInspector(){
    if(this.inspectors.findIndex(x => x.name == this.inspector) != -1){
      if(!this.inspectorsSelected.includes(this.inspector)){
        this.inspectorsSelected.push(this.inspector);
      }else{
        this.toastr.error('Please try a different inspecor.', 'Inspector already selected.')
      }
    }else{
      this.toastr.error('Please try a different inspecor.', 'Inspector does not exist.')
    }
    this.inspector = null;
  }

  removeInspector(inspector){
    let index = this.inspectorsSelected.findIndex(x => x == inspector);
    this.inspectorsSelected.splice(index, 1);
  }

  selectWeather(weather){
    this.weather = weather;
  }

  addWeather(){
    if(this.weathers.findIndex(x => x.weather == this.weather) != -1){
      if(!this.weatherSelected.includes(this.weather)){
        this.weatherSelected.push(this.weather);
      }else{
        this.toastr.error('Please try a different weather.', 'Weather type already selected.')
      }
    }else{
      this.toastr.error('Please try a different weather.', 'Weather type is not valid.')
    }
    this.weather = null;
  }

  removeWeather(weather){
    let index = this.weatherSelected.findIndex(x => x == weather);
    this.weatherSelected.splice(index, 1);
  }

  selectCoatingVert(coating){
    this.coatingVert = coating;
  }

  selectCoatingFlat(coating){
    this.coatingFlat = coating;
  }

  selectCoatingBoot(coating){
    this.coatingBoot = coating;
  }

  selectVisibility(visibility){
    this.visibility = visibility;
  }

  async saveInspectionDetails(){
    let validate = await this.validateInspectionDetails();
    if(validate == null){
      if(this.coatingFlat){
        let flat = this.coatingFlat;
        this.coatingFlat = this.coatings.find(x => x.coating == flat);
        if(this.coatingFlat === undefined){
          this.toastr.error('The coating you entered does not exist. Please use the coatings from the list.', 'Invalid Data')
          return;
        }
      }
      if(this.coatingVert){
        let vert = this.coatingVert;
        this.coatingVert = this.coatings.find(x => x.coating == vert);
        if(this.coatingVert === undefined){
          this.toastr.error('The coating you entered does not exist. Please use the coatings from the list.', 'Invalid Data')
          return;
        }
      }
      if(this.coatingBoot){
        let vert = this.coatingBoot;
        this.coatingBoot = this.coatings.find(x => x.coating == vert);
        if(this.coatingBoot === undefined){
          this.toastr.error('The coating you entered does not exist. Please use the coatings from the list.', 'Invalid Data')
          return;
        }
      }
      let inspectorsSelectedIds = [];
      this.inspectorsSelected.forEach(inspector => {
        if(this.inspectors.length > 0){
          let ins = this.inspectors.find(x => x.name == inspector)
          if(ins === undefined){
            this.toastr.error('One of the inspectors you entered does not exist.', 'Invalid Data')
            return;
          }else{
            ins = ins.id;
            inspectorsSelectedIds.push(ins);
          }
        }else{
          this.toastr.error('One of the inspectors you entered does not exist.', 'Invalid Data')
          return;
        }
      });
      let weatherSelectedIds = [];
      this.weatherSelected.forEach(weather => {
        if(weather !== undefined){
          let weath = this.weathers.find(x => x.weather == weather)
          if(weath === undefined){
            this.toastr.error('One of the weather states you entered does not exist.', 'Invalid Data');
            return;
          }else{
            weath = weath.id;
            weatherSelectedIds.push(weath);
          }
        }
      })
      let portName = this.port;
      if(this.port){
        let port = this.port;
        this.port = this.ports.find(x => x.name == port)
        if(this.port === undefined){
          this.toastr.error('The port you entered does not exist.', 'Invalid Data');
          return;
        }
      }
      if(this.visibility){
        let visibility = this.visibility;
        this.visibility = this.visibilities.find(x => x.visibility == visibility);
        if(this.visibility === undefined){
          this.toastr.error('The port you entered does not exist.', 'Invalid Data');
          return;
        }
      }

      let vesselName = this.vessels.find(x => x.imo == this.vessel).name;

      let purpose = this.purpose
      this.purpose = this.purposes.find(x => x.purpose == purpose);
      if(this.purpose === undefined){
        this.toastr.error('Please select a purpose from the list.', 'Invalid Data');
        return;
      }
      this.visibility = this.visibility.id;
      this.purpose = this.purpose.id;
      this.coatingVert = this.coatingVert.id;
      this.coatingBoot = this.coatingBoot.id;
      this.coatingFlat = this.coatingFlat.id;
      this.port = this.port.id;

      let id = this.db.createId();
      this.db.collection('inspection').doc(id).set({
        id: id,
        date: this.caseDate,
        imo: this.vessel,
        inspectionPurpose: this.purpose,
        country: this.countrySelected,
        city: this.citySelected,
        port: this.port,
        inspectors: inspectorsSelectedIds,
        weathers: weatherSelectedIds,
        coatingVert: this.coatingVert,
        coatingFlat: this.coatingFlat,
        coatingBoot: this.coatingBoot,
        visibility: this.visibility,
        vesselName: vesselName,
        status: "Inspection",
        portName: portName
      }).then(() => this.router.navigate(['transect'], {queryParams: {id: id}}));
    }else{
      this.toastr.error(validate, 'Invalid Data')
    }
    //Find ID's for the various things
    //Go to the point component
  }

  async validateInspectionDetails(): Promise<string>{
    if(this.vessel == null || this.vessel.trim() == ""){
      return  "Vessel is required";
    }else if(this.caseDate == null || this.caseDate.trim() == ""){
      return "Inspection Date is required";
    }
    else if(new Date(this.caseDate).getTime() > new Date().getTime()){
      return "Date must be in the past";
    }else if(this.purpose == null || this.purpose.trim() == ""){
      return "Inspection Purpose is required";
    }else if(this.countrySelected == null || this.countrySelected.trim() == ""){
      return "Country is required";
    }else if(this.port == null || this.port.trim() == ""){
      return "Port is required";
    }else if(this.inspectorsSelected.length < 1){
      return "Inspector is required";
    }else if(this.weatherSelected.length < 1){
      return "Weather is required";
    }else{
      return null;
    }
  }

  pastDate(event){
    this.toastr.clear();
    if(new Date(this.caseDate).getTime() > new Date().getTime()){
      this.toastr.warning('The date of inspection must be in the past.', 'Date Error')
      return;
    }
  }

  overview(){
    this.router.navigate(['overview']);
  }

  setupStripDetail(){
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });
  }

  initDrag(): void{
    const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(this.document, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.document, "mousemove").pipe(
      takeUntil(dragEnd$)
    );

    let initialY: number,
      currentY = 0;

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialY = event.clientY - currentY;

      dragSub = drag$.subscribe((event: MouseEvent) => {
        this.mouseDown = true;
        currentY = event.clientY - initialY;
        if(currentY < 100 && currentY > -1 ){
          this.element.style.top = currentY+"%";
        }
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      if(this.mouseDown){
        initialY = currentY;
        if(currentY < 100 && currentY > -1 ){
          this.setCurrentTime(currentY);
          this.vimeo.videoUpdate = currentY;
        }
        this.mouseDown = false;
      }
      if(dragSub){
        dragSub.unsubscribe();
      }
    });
  }

  setCurrentTime(currentY: number){
    let timestamp = (currentY/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  changeContent(button: string): void{
    switch (button){
      case "btn1": {
        this.activeButton = button;
        this.content = "details";
        break;
      }
      case "btn2": {
        this.activeButton = button;
        this.content = "vessel";
        break;
      }
    }
  }

  addVesselType(type: string): void{
    this.vesselType = type;
  }

  canSubmit(): boolean{
    if(this.vesselType != null){
      if(this.vesselLength > 0 && this.inspectionDepth > 0){
        return true;
      }
    }
    return false;
  }

  canSaveDetails(): boolean{
    if(this.vessel == null || this.vessel == "" || this.port == null
    || this.port == "" || this.caseDate == null || this.caseDate == ""){
      return true;
    }else{
      if(new Date(this.caseDate).getTime() > new Date().getTime()){
        return true;
      }
      return false;
    }
  }

  nextStep(){
    if(this.step == 1){
      this.vesselDb.update({vesselLength: this.vesselLength, inspectionDepth: this.inspectionDepth, vesselDiagram: this.vesselType});
    }
    if(this.step == 2){
      this.starboardSelectedStrips.forEach(element => {
        let section = this.getVesselSection("starboard", element);
        let id = this.db.createId();
        this.vesselDb.collection('inspectionPoint').doc(id).set({shipside: "starboard", strip: element, section: section, id: id});
        this.stripsId.push({strip: element, id: id, shipside: "starboard", section: section});
        this.stripIcons[element + "s"] = {icon1: false, icon2: false, icon3: false, icon4: false}
      });
      this.portSelectedStrips.forEach(element => {
        let section = this.getVesselSection("port", element);
        let id = this.db.createId();
        this.vesselDb.collection('inspectionPoint').doc(id).set({shipside: "port", strip: element, section: section, id: id});
        this.stripsId.push({strip: element, id: id, shipside: "port", section: section});
        this.stripIcons[element + "p"] = {icon1: false, icon2: false, icon3: false, icon4: false}
      });
      console.log(this.stripsId);
    }
    this.step++;
  }

  getVesselSection(side: string, strip){
    //STARBOARD                 PORT
    //0-3 AFT                   28-31
    //4-19 MID                  12-27
    //20-25 SHOULDER            6-11
    //26-31 BOW                 0-5
    if(side == "starboard"){
      if(strip >= 0 && strip < 4){
        return "AFT";
      }else if(strip >= 4 && strip < 20){
        return "MIDSHIP";
      }else if (strip >= 20 && strip < 26){
        return "SHOULDER";
      }else{
        return "BOW";
      }
    }else{
      if(strip >= 0 && strip < 6){
        return "BOW";
      }else if(strip >= 6 && strip < 12){
        return "SHOULDER";
      }else if (strip >= 12 && strip < 28){
        return "MIDSHIP";
      }else{
        return "AFT";
      }
    }
  }

  changeStarboard(starboard: boolean): void{
    this.starboard = starboard;
  }

  inspectionStrip(strip, shipSide): void{
    if(shipSide == "starboard"){
      let tempStrip = this.starboardSelectedStrips.find(x => x == strip);
      this.stripsId.forEach(element => {
        if(element['shipside'] == "starboard"){
          if(element['strip'] == tempStrip){
            this.currentStrip = element;
          }
        }
      });
    }else{
      let tempStrip = this.portSelectedStrips.find(x => x == strip);
      this.stripsId.forEach(element => {
        if(element['shipside'] == "port"){
          if(element['strip'] == tempStrip){
            this.currentStrip = element;
          }
        }
      });
    }
    this.content = "upload";
    this.checkForVideo();
    this.checkForScreenshots();
    this.checkForDepthMarkers();
    this.vimeo.pauseVideo();
  }

  checkForVideo(){
    this.vimeo.removeVideo();
    //if video exists, set it
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).get().subscribe(data => {
      if(data.exists){
        if(!data.data()['videoLink']){
          this.vimeoUpload.setUploaded(false);

        }else if(data.data()['videoLink'] != null){
          this.vimeo.loadVideo(data.data()['videoLink']);
          this.vimeoUpload.setUploaded(true);
        }
        this.vimeo.pauseVideo();
      }
    });
    //if not remove video and flag for upload
    this.vimeoUpload.setUploaded(false);
  }

  checkForDepthMarkers(){
    this.draftNumbers = [];
    this.depthNumbers = [];
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').get().subscribe(elm => {
      if(elm.docs){
        elm.forEach(elmF => {
          let depth = Number(elmF.data()['depth']);
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(elmF.data()['id']).collection('fouling').get().subscribe(foulingCol => {
            if(foulingCol.docs){
              foulingCol.forEach(fouling => {
                if(fouling.data()){
                  this.depthNumbers.push({depth: depth, value: fouling.data()['depthValue'], id: elmF.data()['id']});
                  this.draftNumbers.push({depth: depth, value: fouling.data()['draftValue'], id: elmF.data()['id']});
                }
              })
            }

          })
        })
      }
    });
  }

  checkForScreenshots(){
    this.screenShotId = [];
    let length = this.screenShotId.length-1;
    this.foulingColours = [];
    this.damagedMarkers = [];

    this.screenshots = [];

    this.screenShotActive = false;
    this.currentScreenshotId = null;

    //Check for exisiting ones on the DB
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').get().subscribe(col =>{
      if(!col.empty){
        col.docs.forEach(doc =>{
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(doc.id).collection('fouling').get().subscribe(col => {
            if(!col.empty){
              col.docs.forEach(fDoc => {
                if(fDoc.data()['colour']){
                  this.foulingColours.push({'colour': fDoc.data()['colour'], 'depth': doc.data()['depth']-1.83});
                }
                if(fDoc.data()['damaged']){
                  this.damagedMarkers.push({'depth': doc.data()['depth'] -0.43, 'screenshot': doc.id});
                }
                this.generateScreenshotIconWTime(doc.data()['depth'], doc.data()['videoTime'], doc.id, fDoc.id);
              })
            }
          })

        })
      }
    })
    //Add existing to the DOM
  }

  uploadFile(files: FileList){
    this.fileToUpload = files.item(0);
    this.vimeoUpload.setloading(true);
    let shipSide = this.starboard ? "starboard" : "port"
    console.log(this.currentStrip);
    this.videoLink = this.vimeoUpload.uploadToVimeo(this.fileToUpload, this.vessel, this.currentStrip, shipSide, this.id);
  }

  changeVideoUploadOpen(){
    this.videoUploadOpen = !this.videoUploadOpen;
  }

  stripSelected(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      this.starboardSelectedStrips.push(stripNumber);
    }
    if(shipSide === "port"){
      this.portSelectedStrips.push(stripNumber);
    }
  }

  stripDeleted(stripNumber: number, shipSide: string){
    if(shipSide === "starboard"){
      if(this.starboardSelectedStrips.indexOf(stripNumber) != -1){
        this.starboardSelectedStrips.splice(this.starboardSelectedStrips.indexOf(stripNumber), 1);
      }
    }
    if(shipSide === "port"){
      if(this.portSelectedStrips.indexOf(stripNumber) != -1){
        this.portSelectedStrips.splice(this.portSelectedStrips.indexOf(stripNumber), 1);
      }
    }
  }

  saveDetails(){
    if(this.vessel == null || String(this.vessel).trim() == ""){
      this.toastr.error("You must complete the Vessel Name", "Invalid Data!");
      return;
    }
    if(this.port == null || String(this.port).trim() == ""){
      this.toastr.error("You must complete the port", "Invalid Data!");
      return;
    }
    if(this.caseDate == null || String(this.port).trim() == ""){
      this.toastr.error("You must complete the caseDate", "Invalid Data!");
      return;
    }
    if(this.id == null){
      this.id = this.db.createId();
    }
    let caseId = "Inspection - " + this.vessel + this.caseDate;
    this.vesselDb = this.db.collection('vessel').doc(this.id);
    this.vesselDb.set({vesselName: this.vessel, vesselPort: this.port, caseDate: this.caseDate, caseStatus: "Inspection", docId: this.id, photoLink: this.photo, caseId: caseId});
    this.initialVesselSave = true;
    this.changeContent('btn2');
  }


  playVideo(){
    this.screenShotActive = false;
    this.setValuesToZero();
    this.currentScreenshotId = null;
    this.vimeo.playVideo();
    this.damaged = false;
    this.selectedIcons = [];
  }

  setValuesToZero(){
    for(var i = 0; i < 4; i++){
      this.foulingRatings[i] = 0;
    }
  }

  pauseVideo(){
    this.vimeo.pauseVideo();
  }

  async createScreenshot(){
    this.disableScreenshot = true;
    this.screenshotToast = this.toastr.info("Generating your screenshot now...", "Please Wait!", {
      disableTimeOut: true
    }).toastId;

    this.vimeo.pauseVideo();

    let thumbnailLink = null;

    await this.vimeo.createThumbnail().then((val) => {
      let sizes = [];
      sizes = val['sizes'];
      console.log(sizes[sizes.length-1]['link']);
      thumbnailLink = sizes[sizes.length-1]['link'];
    });
    console.log(thumbnailLink);

    let currentVideoTime = 0;
    await this.vimeo.getCurrentTime().then((val) => {
      currentVideoTime = val;
    });

    let id = this.db.createId();
    this.vesselDb.collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(id).set({videoTime: currentVideoTime, depth: this.vimeo.videoUpdate, pictureLink: thumbnailLink, id: id});
    let foulingId = this.db.createId();
    this.vesselDb.collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(id).collection('fouling').doc(foulingId).set({id: foulingId, sRating: 0, wRating: 0, aRating: 0, a2Rating: 0});
    this.generateScreenshotIcon(id, foulingId);

    console.log(this.currentScreenshotId);
  }

  async generateScreenshotIcon(screenshotId, foulingId){
    this.depthInput = 0;
    this.draftInput = 0;
    let currentVideoTime = 0;
    await this.vimeo.getCurrentTime().then((val) => {
      currentVideoTime = val;
    });

    this.screenShotId.push({screenshot: screenshotId, fouling: foulingId});
    let length = this.screenShotId.length-1;

    this.screenshots.push({"videoTime": currentVideoTime, "videoUpdate": this.vimeo.videoUpdate, "length": length});

    this.screenShotActive = true;
    this.currentScreenshotId = this.screenShotId[length];
    this.toastr.remove(this.screenshotToast);
    this.disableScreenshot = false;
  }

  async generateScreenshotIconWTime(videoUpdate, videoTime, screenshotId, foulingId){
    /*

    {'screenshotId', 'videoTime', 'videoUpdate', 'length'}
    const div = this.renderer.createElement('div');
    const i = this.renderer.createElement('i');
    this.renderer.addClass(i, 'fas');
    this.renderer.addClass(i, 'fa-camera');



    div.addEventListener('click', () => this.getScreenshot(videoTime, videoUpdate, length));

    this.renderer.setStyle(div, 'top', videoUpdate + "px");

    this.renderer.appendChild(div, i);
    this.renderer.appendChild(document.getElementById('screenshotButtonsContainer'), div);
    */

   this.screenShotId.push({screenshot: screenshotId, fouling: foulingId});
   let length = this.screenShotId.length-1;

   this.screenshots.push({"videoTime": videoTime, "videoUpdate": videoUpdate, "length": length});
  }

  getScreenshot(timestamp, videoUpdate, index){
    this.selectedIcons = [];
    this.vimeo.pauseVideo();
    //Apply video time
    this.vimeo.changeVideoTime(timestamp);
    //move bar
    this.vimeo.setVideoUpdate(videoUpdate);

    this.currentScreenshotId = this.screenShotId[index];

    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(this.currentScreenshotId['screenshot']).collection('fouling').doc(this.currentScreenshotId['fouling']).get().subscribe(data => {
      this.damaged = data.data()['damaged'] ? data.data()['damaged'] : false;
      if(data.data()['icon1']){
        this.selectedIcons.push(1);
      }
      if(data.data()['icon2']){
        this.selectedIcons.push(2);
      }
      if(data.data()['icon3']){
        this.selectedIcons.push(3);
      }
      if(data.data()['icon4']){
        this.selectedIcons.push(4);
      }
      this.setFoulingValues( data.data()['sRating'], data.data()['wRating'], data.data()['aRating'], data.data()['a2Rating']);
    })
    this.screenShotActive = true;
  }

  setFoulingValues(s, w, a, a2){
    this.foulingRatings[0] = s;
    this.foulingRatings[1]= w;
    this.foulingRatings[2]= a;
    this.foulingRatings[3] = a2;
  }

  nextStrip(){
    let stripsListLength = this.stripsId.length
    console.log(this.currentStrip['id']);
    let stripCurrentIndex = this.stripsId.findIndex(x => x.id == this.currentStrip['id']);

    if(stripCurrentIndex == stripsListLength - 1){
      let stripCurrent = this.stripsId[0];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }else{
      let stripCurrent = this.stripsId[stripCurrentIndex+1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }
  }

  previousStrip(){
    let stripsListLength = this.stripsId.length
    console.log(this.currentStrip['id']);
    let stripCurrentIndex = this.stripsId.findIndex(x => x.id == this.currentStrip['id']);

    if(stripCurrentIndex == 0){
      let stripCurrent = this.stripsId[stripsListLength - 1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }else{
      let stripCurrent = this.stripsId[stripCurrentIndex-1];
      this.inspectionStrip(stripCurrent['strip'], stripCurrent['shipside']);
    }
  }

  uploadPhoto(file: FileList){
    let toastId = this.toastr.info("Please wait while the image is uploaded...", "Uploading!", {
      disableTimeOut: true
    }).toastId;
    const fileToUpload = file.item(0);
    if(fileToUpload.type.split('/')[0] === 'image'){
      const path = `vessel/${new Date().getTime()}_${fileToUpload.name}`;
      let ref = this.afStorage.ref(path);

      let task = this.afStorage.upload(path, fileToUpload).then(val => {
        this.afStorage.ref(path).getDownloadURL().subscribe(data => {
          this.photo = data;

        });
      }).then(() => this.toastr.remove(toastId)).catch(() => {
        this.toastr.error("There was a problem uploading the image, please try again.", "Upload Error!");
      });
    }
  }

  backToOverview(){
    this.content = "vessel";
  }

  getSliderPercentage(slider){
    return this.foulingRatings[slider];
  }

  setSliderPercentage(slider, value){
    this.foulingRatings[slider] = value;

    return value;
  }

  async saveFoulingRating(){
    if(this.depthInput > 999 || this.draftInput > 999){
      this.toastr.error("Your depth or draft number is invalid.", "Input Error!")
      return;
    }
    let colour = null;

    this.depthNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.depthNumbers.splice(this.depthNumbers.indexOf(elm), 1);
      }
    });

    this.draftNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.draftNumbers.splice(this.draftNumbers.indexOf(elm), 1);
      }
    });

    let hRating = (this.foulingRatings[0] * 0.08) + (this.foulingRatings[1] * 0.1) + (this.foulingRatings[3] * 0.25) + (this.foulingRatings[2] * 0.4);

    if(hRating < 7 && hRating > 0){
      colour = "#1dff00";
    }else if(hRating >= 7 && hRating < 12){
      colour = '#FFC400';
    }else if(hRating >= 12){
      colour = '#FF0700';
    }


    let currentVideoTime = await this.vimeo.getCurrentTime();

    let percentage = (currentVideoTime/this.vimeo.duration)*100;

    this.foulingColours.push({'colour': colour, 'depth': this.vimeo.videoUpdate-1.83});

    if(this.damaged){
      this.damagedMarkers.push({'depth': this.vimeo.videoUpdate-0.43, 'screenshot': this.currentScreenshotId['screenshot']});
    }else{
      this.damagedMarkers.forEach(damage => {
        if(damage['screenshot'] == this.currentScreenshotId['screenshot']){
          const index = this.damagedMarkers.indexOf(damage, 0);
          this.damagedMarkers.splice(index, 1);
        }
      })
    }

    let icon1 = false;
    let icon2 = false;
    let icon3 = false;
    let icon4 = false;

    if(this.selectedIcons.length > 0){
      this.selectedIcons.forEach(icon => {
        if(icon == 1){
          icon1 = true;
        }else if(icon == 2 ){
          icon2 = true;
        }else if(icon == 3 ){
          icon3 = true;
        }else if(icon == 4 ){
          icon4 = true;
        }
      })
    }

    this.vesselDb.collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(this.currentScreenshotId['screenshot']).collection('fouling').doc(this.currentScreenshotId['fouling']).update({sRating: this.foulingRatings[0], wRating: this.foulingRatings[1], aRating: this.foulingRatings[2], a2Rating: this.foulingRatings[3], colour: colour, percentage: percentage, damaged: this.damaged,
      icon1: icon1, icon2: icon2, icon3: icon3, icon4: icon4, depthValue: this.depthInput, draftValue: this.draftInput});

    let shipSide = this.starboard ? 's' : 'p';

    let strip = this.currentStrip['strip']+shipSide;

    if(!this.stripIcons[strip]['icon1'] && icon1){
      this.stripIcons[strip]['icon1'] = icon1;
    }

    if(!this.stripIcons[strip]['icon2'] && icon2){
      this.stripIcons[strip]['icon2'] = icon2;
    }
    if(!this.stripIcons[strip]['icon3'] && icon3){
      this.stripIcons[strip]['icon3'] = icon3;
    }
    if(!this.stripIcons[strip]['icon4'] && icon4){
      this.stripIcons[strip]['icon4'] = icon4;
    }

    this.stripDetail[strip] = [];

    let detailsPercentage = (67*(percentage/100)) + 23;

    this.stripDetail[strip].push({'colour': colour, 'percentage': detailsPercentage});

    this.depthNumbers.push({depth: this.vimeo.videoUpdate, value: this.depthInput, id: this.currentScreenshotId['screenshot']});
    this.draftNumbers.push({depth: this.vimeo.videoUpdate, value: this.draftInput, id: this.currentScreenshotId['screenshot']});
    this.getStripDetail();
  }

  getStripDetail(){
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });

    let strip = -1;
    let shipSide = null;
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').get().subscribe(data => {
      if(data.docs){
        data.docs.forEach(elm => {
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').get().subscribe(screenshot => {
            if(screenshot.docs){
              screenshot.docs.forEach(screenshotElmt => {
                this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').doc(screenshotElmt.data()['id']).collection('fouling').get().subscribe(val => {
                  if(val.docs){
                    val.docs.forEach(foulingVl => {
                      shipSide = elm.data()['shipside'];
                      strip = elm.data()['strip'];
                      if(shipSide == "starboard"){
                        let stripName = strip+"s";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage});
                        }
                      }else{
                        let stripName = strip+"p";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage});
                        }
                      }
                    });
                  }
                });
              });
            }
          });
        });
      }
    });
  }

  screenshotActive(index){
    if(this.currentScreenshotId != null){
      return this.currentScreenshotId == this.screenShotId[index];
    }
    return false;
  }

  getStripDetails(strip, shipSide){
    return this.stripDetail[strip + shipSide];
  }

  setDamaged(){
    this.damaged = !this.damaged;
  }

  selectIcon(icon: number){
    if(this.selectedIcons.includes(icon)){
      this.selectedIcons.splice(this.selectedIcons.indexOf(icon), 1);
    }else{
      this.selectedIcons.push(icon);
    }
  }

  iconSelected(icon: number){
    return this.selectedIcons.includes(icon) ? true : false;
  }

  getStripIcons(side, icon, strip){
    try{
      if(icon == 1){
        return !this.stripIcons[strip + side]['icon1'];
      }
      if(icon == 2){
        return !this.stripIcons[strip + side]['icon2'];
      }
      if(icon == 3){
        return !this.stripIcons[strip + side]['icon3'];
      }
      if(icon == 4){
        return !this.stripIcons[strip + side]['icon4'];
      }
      return true;
    }catch(err){
      return false;
    }
  }

  srubVideo(value){
    let timestamp = (value/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  refresh(){
    location.reload();
  }
}
