import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InspectionNavService {

  page: string = null;

  constructor() { }

  setPage(page: string){
    this.page = page;
  }
}
