import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import * as firebase from 'firebase/compat';
import { EmailAuthProvider } from 'firebase/auth'
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  displayName     = "Caroline Brown";
  role            = "AkzoNobel Claims Manager";
  uid             = null;
  email           = null;
  confirmEmail    = null;
  oldPassword     = null;
  newPassword     = null;
  confirmPassword = null;

  msg             = '';

  photo           = null;
  photoUploaded   = false;
  options = {
    autoClose: true,
    keepAfterRouting: false
  }

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private toastr: ToastrService,
    private router: Router,
    private afStorage: AngularFireStorage,
    private headerService: HeaderService,) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "profile";
    this.afAuth.currentUser.then(val => {
      this.displayName = val.displayName;
      this.email = val.email;
      this.uid = val.uid;
      this.photo = val.photoURL ? val.photoURL : 'assets/images/blank-profile-picture-973460_1280.jpg';
      this.photoUploaded = val.photoURL ? true : false;

      this.db.collection('user').doc(this.uid).get().subscribe(val => {
        this.role = val.data()['role'];
      });
    });
  }

  changeDisplayName(){
    if(this.displayName == null || this.displayName == ""){
      this.toastr.error("Display name must have a value!", "Error");
    }else{
      this.afAuth.currentUser.then(result => {
        result.updateProfile({
          displayName: this.displayName
        })
      }).then(() => {
        this.toastr.success('Display name saved!', 'Success');
      }).catch(err => {
        this.toastr.error("Something went wrong please try again :(", "Error");
      });
    }
  }

  changeEmail(){
    if(this.email != this.confirmEmail){
      this.toastr.error("Emails do not match!", "Error");
    }else{
      this.afAuth.currentUser.then(result => {
        result.updateEmail(this.email);
      }).then(response => {
        this.toastr.success("Email changed successfully!", "Success")
      }).catch(err => {
        this.toastr.error("Something went wrong please try again :(", "Error");
      })
    }
  }

  changePassword(){
    if(this.newPassword == this.confirmPassword){
      const credential = EmailAuthProvider.credential(
        this.email,
        this.oldPassword
      );

      this.afAuth.currentUser.then(result => {
        result.reauthenticateWithCredential(credential).then(res => {
          this.afAuth.currentUser.then(result => {
            result.updatePassword(this.newPassword)
          }).then(() => {
            this.toastr.success("Password changed successfully!", "Success");
            this.afAuth.signOut();
            this.router.navigate(['/login']);
          }).catch(err => {
            this.toastr.error("Something went wrong please try again :(", "Error");
            return;
          });
        }).catch(err => {
          this.toastr.error("Old password is incorrect", "Error");
          return;
        });
      });
    }else {
      this.toastr.error("Passwords do not match", "Error");
    }
  }

  uploadPhoto(file: FileList){
    let toastId = this.toastr.info("Please wait while the image is uploaded...", "Uploading!", {
      disableTimeOut: true
    }).toastId;
    const fileToUpload = file.item(0);
    if(fileToUpload.type.split('/')[0] === 'image'){
      const path = `profile/${new Date().getTime()}_${fileToUpload.name}`;
      let ref = this.afStorage.ref(path);

      let task = this.afStorage.upload(path, fileToUpload).then(val => {
        this.afStorage.ref(path).getDownloadURL().subscribe(data => {
          this.photo = data;
          this.photoUploaded = true;
          this.afAuth.currentUser.then(result => {
            result.updateProfile({
              photoURL: data
            });
          }).then(() => {
            this.toastr.success('Photo Uploaded!', 'Success');
            this.toastr.remove(toastId);
          }).catch(err => {
            this.toastr.error("Something went wrong please try again :(", "Error");
          });
        });
      });
    }
  }
}
