import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { interval } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { VimeoService } from './vimeo.service';



@Injectable({
  providedIn: 'root'
})
export class VimeoUploadService {

  private accessToken = '0e88c9e8b5e8d19ac76be10f957f3343';

  uploadData          = null;

  uploadOffset        = '0';

  uploadFile: File    = null;

  videoLink           = null;
  basePlayerUrl       = "https://player.vimeo.com/video"
  videoId             = null;

  uploading           = false;
  uploaded            = false;

  strip               = null;
  side                = null;
  stripsId            = null;
  collectionId        = null;

  vesselDb            = null;

  uploadStatus        = "uploading please wait";

  constructor(private httpClient: HttpClient,
    private db: AngularFirestore,
    private vimeo: VimeoService) { }

  public uploadToVimeo(file: File, name: string, strip, side, collectionId){
    this.strip = strip;
    this.side  = side;
    this.collectionId = collectionId;

    let headers = new HttpHeaders({
      'Authorization': 'bearer ' + this.accessToken,
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.vimeo.*+json;version=3.4'
    });

    let body = {
      "upload": {
        "approach": "tus",
        "size": file.size.toString()
      },
      "name": name + "-" + side + "-" + strip.stripId + "-" + Math.random().toString(),
      //"privacy": { "view": "unlisted" }
    };


    this.httpClient.post("https://api.vimeo.com/me/videos", body, {headers: headers}).subscribe(data => {
      console.log(data);
      this.uploadData = data;
      this.uploadFile = file;
      this.sendFile();
    });
  }


  private sendFile(){

    let headers = new HttpHeaders({
      'Tus-Resumable': '1.0.0',
      'Upload-Offset': '0',
      'Content-Type': '	application/offset+octet-stream',
      'Accept': 'application/vnd.vimeo.*+json;version=3.4'
    });

    this.httpClient.patch(this.uploadData['upload']['upload_link'], this.uploadFile, {headers: headers}).subscribe(data => {
      this.checkUpload();
    });
  }

  private checkUpload(){
    let headers = new HttpHeaders({
      'Tus-Resumable': '1.0.0',
      'Accept': 'application/vnd.vimeo.*+json;version=3.4'
    });

    this.httpClient.head(this.uploadData['upload']['upload_link'], {headers: headers, observe: 'response'}).subscribe((event: HttpResponse<any>) =>{
      let videoLength = event.headers.get('Upload-Length');
      let videoOffset = event.headers.get('Upload-Offset');

      console.log((Number(videoOffset)/Number(videoLength)) * 100 )

      console.log(event)

      if(videoLength == videoOffset){
        this.uploadOffset = '0';
        this.videoLink = this.basePlayerUrl + this.uploadData['uri'].substr(7, this.uploadData['uri'].length-1);
        this.videoId =  this.uploadData['uri'].substr(8, (this.uploadData['uri'].length-1));
        this.displayVideo();
      }
      else if(videoLength > videoOffset){
        this.uploadOffset = videoOffset.toString();
        this.sendFile();
      }
    });
  }

  public getVideoLink(){
    return this.videoLink;
  }

  public setVideoLink(videoLink: string){
    this.videoLink = videoLink;
  }

  displayVideo(){
    let headers = new HttpHeaders({
      'Authorization': 'bearer ' + this.accessToken,
      'Content-Type': 'application/json'
    });

    var count = interval(2000);

    let countSub = count.subscribe(() =>{
      this.httpClient.get('https://api.vimeo.com' + this.uploadData['uri'] + '?fields=status', {headers: headers}).subscribe(data => {
        this.uploadStatus = data['status'].replace("_", " ");
        if(data['status'] == 'available'){

          this.vimeo.setVideo(this.videoLink);
          this.setloading(false);
          console.log(this.getLoading());
          this.uploaded = true;

          this.putInFolder();

          console.log(this.strip);
          this.db.collection('transect').doc(this.collectionId).update({videoLink: this.videoLink});

          this.vimeo.pauseVideo();
          this.uploadStatus = "uploading please wait";
          countSub.unsubscribe();
        }
      })
    })
  }

  putInFolder(){
    let headers = new HttpHeaders({
      'Authorization': 'bearer ' + this.accessToken
    });

    let folderLink = "https://api.vimeo.com/me/projects/2950893/videos/"+ this.videoId;

    this.httpClient.put(folderLink, '', {headers: headers}).subscribe(data => {
      return;
    });
  }

  setloading(bool: boolean){
    this.uploading = bool
  }

  getLoading(){
    return this.uploading;
  }

  getUploaded(){
    return this.uploaded;
  }

  setUploaded(bool: boolean){
    this.uploaded = bool;
  }

  getUploadStatus(){
    return this.uploadStatus.toUpperCase() + "...";
  }
}
