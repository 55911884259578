import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { LoadingService } from '../loading.service';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-vessel-overview',
  templateUrl: './vessel-overview.component.html',
  styleUrls: ['./vessel-overview.component.scss']
})
export class VesselOverviewComponent implements OnInit {

  vessels = null;

  currentFilter    = {field: "", direction: 0};

  constructor(private loadingService: LoadingService,
    private router: Router,
    private db: AngularFirestore,
    private headerService: HeaderService,) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "vessel";
    this.loadingService.setLoadingScreen(false);
    this.getVessels();
  }

  getVessels(){
    this.vessels = this.db.collection('vessel').valueChanges();
  }

  navigateToVessel(id?: string){
    if(id){
      this.router.navigate(['vessel'], {queryParams: {id: id}});
    }else{
      this.router.navigate(['vessel'])
    }
  }

  filterList(field: string, direction: number){
    if(direction == 1){
      if(this.currentFilter.direction == 1 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getVessels();
      }else{
        this.currentFilter = {field: field, direction: 1};
        this.vessels = this.db.collection('vessel', ref => ref.orderBy(field, "asc")).valueChanges();
      }
    }else{
      if(this.currentFilter.direction == 2 && field == this.currentFilter.field){
        this.currentFilter = {field: "", direction: 0};
        this.getVessels();
      }else{
        this.currentFilter = {field: field, direction: 2};
        this.vessels = this.db.collection('vessel', ref => ref.orderBy(field, "desc")).valueChanges();
      }
    }
  }

  filterSelected(field: string, direction: number){
    return this.currentFilter.field == field && this.currentFilter.direction == direction;
  }

}
