export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAe7LK829F2-MhT-pCxB3FLR5OQblI1fhw",
    authDomain: "akzo-production.firebaseapp.com",
    projectId: "akzo-production",
    storageBucket: "akzo-production.appspot.com",
    messagingSenderId: "779545359681",
    appId: "1:779545359681:web:e855edeb12b55426645dc0",
    measurementId: "G-YKNX234Q01"
  }
};
