<div class="header">
<div class="logos">
<div class="main-img">
<img [routerLink]="[&#39;/overview&#39;]" src="assets/images/logo.png">
</div>
<div class="btn-container">
<div [ngClass]="{&#39;actived&#39;: getCurrentPage() == &#39;vessel&#39;}" [routerLink]="[&#39;/vessels&#39;]" class="btn-header" title="Vessels">
<div [ngClass]="{&#39;btn-vessel-o&#39;: getCurrentPage() == &#39;vessel&#39;}" [routerLink]="[&#39;/vessels&#39;]" class="btn-vessel" title="New Inspection"></div>
<span>Vessels</span>
</div>
<div [ngClass]="{&#39;actived&#39;: getCurrentPage() == &#39;inspection&#39;}" [routerLink]="[&#39;/overview&#39;]" class="btn-header" title="Overview">
<div [ngClass]="{&#39;btn-overview-o&#39;: getCurrentPage() == &#39;inspection&#39;}" [routerLink]="[&#39;/overview&#39;]" class="btn-overview" title="Overview"></div>
<span>Inspections</span>
</div>
<div [ngClass]="{&#39;actived&#39;: getCurrentPage() == &#39;profile&#39;}" [routerLink]="[&#39;/profile&#39;]" class="btn-header" title="Profile">
<div [ngClass]="{&#39;btn-profile-o&#39;: getCurrentPage() == &#39;profile&#39;}" [routerLink]="[&#39;/profile&#39;]" class="btn-profile" title="Profile"></div>
<span>Profile</span>
</div>
<div (click)="logout()" class="btn-header" title="Logout">
<div class="btn-logout"></div>
<span>Sign Out</span>
</div>
</div>
<div class="img">
<img src="assets/images/AkzoNobel_wordmark_RGB.png">
</div>
</div>
</div>
