import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingScreen = false;

  constructor() { }

  setLoadingScreen(bool: boolean){
    this.loadingScreen = bool;
  }

  getLoadingScreen(){
    return this.loadingScreen;
  }
}
