<app-header></app-header>
<div class="title-bar">
  <span>{{vessel['name']}}</span>
  <span>{{' - ' + port +' - '}}</span>
  <span>{{inspection['date'] | date}}</span>
</div>
<div class="content">
  <div class="title-container">
    <h1 class="title">{{"Inspection Transect - " + (getNumber(currentStrip['stripId'])+1)}}</h1>
    <div class="buttons">
      <div class="next-btn" (click)="previousStrip()" [ngClass]="{'disabled': transects.length <= 1}">
        Previous Transect
      </div>
      <div class="next-btn" (click)="nextStrip()" [ngClass]="{'disabled': transects.length <= 1}">
        Next Transect
      </div>
      <div class="back-button" (click)="backToOverview()">
        Back
      </div>
    </div>
  </div>
  <div class="bottom-container">
    <div class="left">
      <div class="depth-container">
        <span class="draft-title">DRAFT (M)</span>
        <span class="depth-title">DEPTH (M)</span>
        <div class="depth-update-container">
          <hr *ngFor="let colour of foulingColours"
            [ngStyle]="{'top': colour['depth'] + '%', 'background': colour['colour']}"
            class="colour-container">
          <hr *ngFor="let damage of damagedMarkers"
            [ngStyle]="{'top': damage['depth'] + '%'}" class="damaged-container">
          <hr #videoUpdate="" [ngStyle]="{'top': vimeo.getVideoUpdate() + '%'}" class="depth-update"
            id="depth-update">
        </div>
        <div class="screenshotButtonsContainer">
          <div
            (click)="getScreenshot(shot['videoTime'], shot['videoUpdate'], shot['length'])"
            *ngFor="let shot of screenshots"
            [ngClass]="{'selected-screenshot': screenshotActive(shot['length'])}"
            [ngStyle]="{'top': shot['videoUpdate'] + '%'}" class="screenshot-container">
            <i class="fas fa-camera"></i>
            <span>Snapshot</span>
          </div>
        </div>
        <div class="depths-container">
          <div *ngFor="let depth of depthNumbers" [ngStyle]="{'top': depth['depth'] + '%'}"
            class="depth-number">
            <span>{{depth['value']}}</span>
          </div>
        </div>
        <div class="drafts-container">
          <div *ngFor="let draft of draftNumbers" [ngStyle]="{'top': draft['depth'] + '%'}"
            class="draft-number">
            <span>{{draft['value']}}</span>
          </div>
        </div>
        <div *ngFor="let level of levelDepth"
          [ngClass]="{'first': levelDepth.indexOf(level) == 0, 'last': levelDepth.indexOf(level) == 10}"
          class="depth">
          <span class="depth-level"></span>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="video-elements-container">
        <div class="boat-top">
          <div class="left-top">
            <span>{{currentStrip['shipSide'] + ': ' + currentStrip['shipLocation']}}</span>
          </div>
          <div (click)="createScreenshot()" [ngClass]="{'disabled': !vimeoUpload.getUploaded() || disableScreenshot}"
          class="screenshot-button" title="Take Screenshot">
            Create Snapshot
          </div>
        </div>
        <div (click)="changeVideoUploadOpen()" [ngClass]="{'display-none': vimeoUpload.getUploaded()}"
          class="video-container">
          <i [ngClass]="{'display-none': vimeoUpload.getLoading()}" class="fas fa-film"></i>
          <div class="middle">
            <span [hidden]="videoUploadOpen || vimeoUpload.getLoading()">CLICK TO UPLOAD VIDEO</span>
            <div [hidden]="!vimeoUpload.getLoading()" class="info-loader">
              <div class="box-loader"></div>
            </div>
            <span [hidden]="!vimeoUpload.getLoading()">{{vimeoUpload.getUploadStatus()}}</span>
            <span [hidden]="!vimeoUpload.getLoading()">Do not close you browser. This could take minutes...</span>
            <input (change)="uploadFile($event.target.files)" [hidden]="!videoUploadOpen || vimeoUpload.getLoading()"
              accept="video/mp4, video/mov, video/wmv, video/flv, video/avchd" type="file">
          </div>
        </div>
        <div [ngClass]="{'display-none': !vimeoUpload.getUploaded()}" id="video-player-container">
          <div class="play-pause-container" (click)="playOrPause()"></div>
          <div class="refresh" *ngIf="refreshEnabled">
            <span>Has something went wrong?</span>
            <div (click)="refresh()" class="refresh-button">
              <i class="fas fa-redo"></i>
              <span>Refresh</span>
            </div>
          </div>
          <div class="video-scrub">
            <input #ref4="" (input)="srubVideo(ref4.value)" [ngClass]="{'display-none': !vimeoUpload.getUploaded()}"
              max="100" min="0" type="range" value="{{vimeo.getVideoUpdate()}}">
          </div>
          <div class="play-button">
            <i
              [ngClass]="{'display-none': !vimeo.getPaused() || !vimeoUpload.getUploaded(), 'disabled': !vimeoUpload.getUploaded()}"
              class="fas fa-pause"></i>
            <i
              [ngClass]="{'display-none': vimeo.getPaused() || !vimeoUpload.getUploaded(), 'disabled': !vimeoUpload.getUploaded(), 'pause': !vimeo.paused}"
              class="fas fa-play pause" id="pause"></i>
          </div>
        </div>
      </div>
      </div>
    <div  [ngClass]="{'greyed': !screenShotActive}" class="right">
      <div class="depth-draft-container">
        <h2>Depth and Draft levels</h2>
        <div class="input">
          <label for="depthInput">Depth (M):</label>
          <input [(ngModel)]="depthInput" id="depthInput" type="text">
        </div>
        <div class="input">
          <label for="draftInput">Draft (M):</label>
          <input [(ngModel)]="draftInput" id="draftInput" type="text">
        </div>
      </div>
      <div class="fouling-title">
        <h2>
          <span>Fouling Levels - </span>
          <span [style.color]="getFoulingTotal() > 100 ? 'red' : 'white'">{{getFoulingTotal() + "%"}}</span>
        </h2>
        <small>At the current depth</small>
      </div>
      <div class="fouling-level-input-container">
        <div class="fouling-level-input">
          <h2 class="fouling-label">Slime</h2>
          <span class="percentage">{{getSliderPercentage(0)}}%</span>
          <input #ref="" (change)="setSliderPercentage(0, ref.value)" [(ngModel)]="foulingRatings[0]" id="slider0"
            max="100" min="0" type="range" value="0">
        </div>
        <div class="fouling-level-input">
          <h2 class="fouling-label">Weed</h2>
          <span class="percentage">{{getSliderPercentage(1)}}%</span>
          <input #ref1="" (change)="setSliderPercentage(1, ref1.value)" [(ngModel)]="foulingRatings[1]" id="slider1"
            max="100" min="0" type="range" value="0">
        </div>
        <div class="fouling-level-input">
          <h2 class="fouling-label">Animal Hard Body</h2>
          <span class="percentage">{{getSliderPercentage(2)}}%</span>
          <input #ref2="" (change)="setSliderPercentage(2, ref2.value)" [(ngModel)]="foulingRatings[2]" id="slider2"
            max="100" min="0" type="range" value="0">
        </div>
        <div class="fouling-level-input">
          <h2 class="fouling-label">Animal Soft Body</h2>
          <span class="percentage">{{getSliderPercentage(3)}}%</span>
          <input #ref3="" (change)="setSliderPercentage(3, ref3.value)" [(ngModel)]="foulingRatings[3]" id="slider3"
            max="100" min="0" type="range" value="0">
        </div>
        <div class="fouling-level-input">
          <h2 class="fouling-label">Damage</h2>
          <span class="percentage">{{getSliderPercentage("damage")}}%</span>
          <input #ref50="" (change)="setSliderPercentage('damage', ref50.value)" [(ngModel)]="damageRating" id="slider4"
            max="100" min="0" type="range" value="0">
        </div>
      </div>
      <div class="icon-container">
        <small>Vessel features visible at this depth</small>
        <select id="icons" [(ngModel)]="selectedIcon">
          <option value="featureProp">Prop</option>
          <option value="featureBilge">Bilge</option>
          <option value="featureChest">Chest</option>
          <option value="featureDraftmark">Draftmark</option>
          <option value="featureRudder">Rudder</option>
          <option value="null">No Visible Feature</option>
        </select>

  <!--       <div class="icons">
          <img (click)="selectIcon(1)" [ngClass]="{'unselected': !iconSelected(1)}"
            src="./../../../assets/images/vessel-features/1.png">
          <img (click)="selectIcon(2)" [ngClass]="{'unselected': !iconSelected(2)}"
            src="./../../../assets/images/vessel-features/2.png">
          <img (click)="selectIcon(3)" [ngClass]="{'unselected': !iconSelected(3)}"
            src="./../../../assets/images/vessel-features/3.png">
          <img (click)="selectIcon(4)" [ngClass]="{'unselected': !iconSelected(4)}"
            src="./../../../assets/images/vessel-features/4.png">
        </div> -->
      </div>
      <div class="locations">
        <div class="location">
          <input type="checkbox" name="location" id="waterline" value="waterline" [(ngModel)]="waterline" [ngModelOptions]="{standalone: true}" (change)="checkLocation(true, $event)">
          <label for="waterline">Waterline</label>
        </div>
        <div class="location">
          <input type="checkbox" name="location" id="flats" value="flats" [(ngModel)]="flats" [ngModelOptions]="{standalone: true}" (change)="checkLocation(false, $event)">
          <label for="flats">Flats</label>
        </div>
      </div>
      <div class="btn-container">
        <div (click)="saveFoulingRating()" class="save-button" [ngClass]="{'half-width': newScreenshot}">
          <span>Save Ratings</span>
        </div>
        <div class="save-button delete half-width" (click)="deleteScreenshot()" *ngIf="newScreenshot">
          <i class="fas fa-trash-alt"></i>
          <span>Delete</span>
        </div>
      </div>
  </div>
  </div>
</div>

