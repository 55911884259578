import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from '../service/header.service';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-interactive-report',
  templateUrl: './interactive-report.component.html',
  styleUrls: ['./interactive-report.component.scss']
})
export class InteractiveReportComponent implements OnInit {

  @Input()
  inspection;

  reportGenerated: boolean = false;
  reportGenerating: boolean = false;

  coatingVert = null;
  coatingFlat = null;
  coatingBoot = null;
  visibility = null;
  weathers = [];
  weatherString = null;
  inspectors = [];
  inspectorString = null;

  reportGeneratingToast: number;

  transects = [];
  points = [];

  overallHRating: Number = 0;
  overallBowRating: Number = 0;
  overallShoulderRating: Number = 0;
  overallMidshipRating: Number = 0;
  overallAftRating: Number = 0;

  overallWaterlineRating: Number = 0;
  overallFlatsRating: Number = 0;

  overallPortRating: Number = 0;
  portBowRating: Number = 0;
  portShoulderRating: Number = 0;
  portMidshipRating: Number = 0;
  portAftRating: Number = 0;
  portWaterlineRating: Number = 0;
  portFlatsRating: Number = 0;

  overallStarboardRating: Number = 0;
  starBowRating: Number = 0;
  starShoulderRating: Number = 0;
  starMidshipRating: Number = 0;
  starAftRating: Number = 0;
  starWaterlineRating: Number = 0;
  starFlatsRating: Number = 0;

  starboardSelectedStrips = [];
  portSelectedStrips = [];
  stripIcons = [];
  stripsId = null;
  stripDetail = [];

  vessel = null;
  vesselType = null;
  vesselTypePic = null;

  portClosed: boolean = false;
  starClosed: boolean = false;
  vesselMainClosed: boolean = false;
  propRudClosed: boolean = false;
  waterFlatClosed: boolean = false;
  recClosed: boolean = false;

  strips = [];

  bowComments: string = "";
  shoulderComments: string = "";
  midshipComments: string = "";
  aftComments: string = "";

  propComments: string = "";
  rudComments: string = "";

  waterlineComments: string = "";
  flatsComments: string = "";

  actions: string = "";

  reportId: string = null;

  constructor(private db: AngularFirestore,
    private afStorage: AngularFireStorage,
    private toastr: ToastrService,
    private router: Router,
    private headerService: HeaderService,
    private loadingService: LoadingService) { }

  async ngOnInit(): Promise <void> {
    this.headerService.CurrentPage = "inspection";
    this.strips = Array(32).fill(1, 0, 32).map((x, i) => i);
    this.setupStrips();
    await (await this.db.collection('report').ref.where('inspectionId', '==', this.inspection.id).get()).docs.forEach(async (report: any )=> {
      if(report.exists){
        this.reportGenerating = true;
        this.reportGeneratingToast = this.toastr.info('Gathering your report. Please wait.', 'Please Wait', {
          disableTimeOut: true
        }).toastId
        let rep = report.data();
        this.overallHRating = rep.overallHRating;
        this.overallBowRating = rep.overallBowRating;
        this.overallShoulderRating = rep.overallShoulderRating;
        this.overallMidshipRating = rep.overallMidshipRating;
        this.overallAftRating = rep.overallAftRating;
        this.overallWaterlineRating = rep.overallWaterlineRating;
        this.overallFlatsRating = rep.overallFlatsRating;
        this.overallPortRating = rep.overallPortRating;
        this.portBowRating = rep.portBowRating;
        this.portShoulderRating = rep.portShoulderRating;
        this.portMidshipRating = rep.portMidshipRating;
        this.portAftRating = rep.portAftRating;
        this.portWaterlineRating = rep.portWaterlineRating;
        this.portFlatsRating = rep.portFlatsRating;
        this.overallStarboardRating = rep.overallStarboardRating;
        this.starBowRating = rep.starBowRating;
        this.starShoulderRating = rep.starShoulderRating;
        this.starMidshipRating = rep.starMidshipRating;
        this.starAftRating = rep.starAftRating;
        this.starWaterlineRating = rep.starWaterlineRating;
        this.starFlatsRating = rep.starFlatsRating;
        this.bowComments = rep.bowComments;
        this.shoulderComments = rep.shoulderComments;
        this.midshipComments = rep.midshipComments;
        this.aftComments = rep.aftComments;
        this.propComments = rep.propComments;
        this.rudComments = rep.rudComments;
        this.waterlineComments = rep.waterlineComments;
        this.flatsComments = rep.flatsComments;
        this.actions = rep.actions,
        this.inspectorString = rep.inspectorString;
        this.reportId = rep.id;
        this.vessel = rep.vessel;
        this.coatingBoot = rep.coatingBoot;
        this.coatingVert = rep.coatingVert;
        this.coatingFlat = rep.coatingFlat;
        this.visibility = rep.visibility;
        this.weatherString = rep.weatherString;
        this.vessel = rep.vessel;
        this.vesselTypePic = rep.vesselTypePic;

        setTimeout(async () => {
          await this.getPointInformation().then(() => {
            //
            this.reportGenerated = true;
            this.reportGenerating = false;
            this.toastr.remove(this.reportGeneratingToast);
          })
        }, 1000)

      }
    })
  }

  navigateTransects() {
    this.router.navigate(['transect'], { queryParams: { id: this.inspection.id } });
  }

  resetValues(){
    this.overallHRating = null;
    this.overallBowRating = null;
    this.overallShoulderRating = null;
    this.overallMidshipRating = null;
    this.overallAftRating = null;
    this.overallWaterlineRating = null;
    this.overallFlatsRating = null;
    this.overallPortRating = null;
    this.portBowRating = null
    this.portShoulderRating = null;
    this.portMidshipRating = null;
    this.portAftRating = null;
    this.portWaterlineRating = null;
    this.portFlatsRating = null;
    this.overallStarboardRating = null;
    this.starBowRating = null;
    this.starShoulderRating = null;
    this.starMidshipRating = null;
    this.starAftRating = null;
    this.starWaterlineRating = null;
    this.starFlatsRating = null;
    this.inspectorString = null;
    this.weatherString = null;
  }

  async generateReport() {
    this.reportGenerated = false;
    this.reportGenerating = true;
    this.reportGeneratingToast = this.toastr.info("Generating Report Now...", "Please Wait!", {
      disableTimeOut: true
    }).toastId;

    this.resetValues();

    await this.getPointInformation().finally(async () => {
      setTimeout(async () => {
        await this.calclateOverallHrating();
        await this.calculateSectonOverallRatings();
        await this.calculatePortHrating();
        await this.calculateStarHrating().then(() => {
          this.reportGenerating = false;
          this.reportGenerated = true;
          if(this.reportId){
            this.saveNewGeneratedReport();
          }else{
            this.saveGeneratedReport();
          }
          this.toastr.remove(this.reportGeneratingToast);
        });
      }, 3000)
    });



    this.db.collection('vessel').doc(this.inspection.imo.trim()).get().subscribe(res => {
      if (res.exists) {
        this.vessel = res.data();
        this.db.collection('vesselType').doc(this.vessel.type.trim()).get().subscribe(resType => {
          if (resType.exists) {
            this.vesselType = resType.get('name');
            this.vesselTypePic = resType.get('pictureLink');
          }
        })

      }
    })


    this.db.collection('waterVisibility').get().subscribe(async visibilities => {
      if (!visibilities.empty) {
        let vis = [];
        visibilities.forEach(visibility => {
          vis.push(visibility.data());
        })
        this.visibility = await vis.find(x => x.id == this.inspection.visibility).visibility;
      }
    });

    this.db.collection('coating').get().subscribe(async coatings => {
      if (!coatings.empty) {
        let coats = [];
        coatings.forEach(coating => {
          coats.push(coating.data());
        })
        this.coatingVert = await coats.find(x => x.id == this.inspection.coatingVert).coating;
        this.coatingFlat = await coats.find(x => x.id == this.inspection.coatingFlat).coating;
        this.coatingBoot = await coats.find(x => x.id == this.inspection.coatingFlat).coating;
      }
    });

    await this.inspection.inspectors.forEach(inspectorId => {
      this.db.collection('inspector').doc(inspectorId).get().subscribe((ins: any) => {
        if (ins.exists) {
          let insArray: Array<string> = ins.data().name.split(" ");
          console.log(insArray);
          insArray[0] = insArray[0].charAt(0).toUpperCase();
          let insp = insArray.join(" ");
          this.inspectors.push(insp);
          if (this.inspectorString == null) {
            if (this.inspection.inspectors.length > 1) {
              this.inspectorString = insp + " & ";
            } else {
              this.inspectorString = insp;
            }
          } else {
            let index = this.inspection.inspectors.findIndex(x => x == inspectorId);
            if ((this.inspection.inspectors.length - 1) == index) {
              this.inspectorString = this.inspectorString + insp;
            } else {
              this.inspectorString = this.inspectorString + insp + " & ";
            }
          }
        }
      })
    });

    await this.inspection.weathers.forEach(weatherId => {
      this.db.collection('weather').doc(weatherId).get().subscribe((weath: any) => {
        if (weath.exists) {
          let weathe = weath.data();
          this.weathers.push(weath.data());
          if (this.weatherString == null) {
            if (this.inspection.weathers.length > 1) {
              this.weatherString = weathe.weather + " & ";
            } else {
              this.weatherString = weathe.weather;
            }
          } else {
            let index = this.inspection.weathers.findIndex(x => x == weatherId);
            if ((this.inspection.weathers.length - 1) == index) {
              this.weatherString = this.weatherString + weathe.weather;
            } else {
              this.weatherString = this.weatherString + weathe.weather + " & ";
            }
          }
        }
      })
    });

    /*     setTimeout(() => {
          this.reportGenerating = false;
          this.toastr.remove(this.reportGeneratingToast);
        }, 2000) */
  }

  async getPointInformation() {
    await this.db.collection('transect').ref.where('inspectionId', '==', this.inspection.id).get().then(async transects => {
      if (!transects.empty) {
        transects.forEach(transect => {
          let transData = transect.data();
          this.transects.push(transData);
        })
        this.getStripSides()
        this.transects.forEach(async transect => {
          (await this.db.collection('point').ref.where('transectId', '==', transect.id).get()).forEach(point => {
            if (point.exists) {
              this.points.push(point.data());
              this.setupStripDetailForTransect(transect);
            }
          })
        });
      } else {
        this.toastr.error("There are no transects available. Please create some to generate your report.", "No Transects");
        this.reportGenerated = false;
        this.reportGenerating = false;
        this.toastr.remove(this.reportGeneratingToast);
        return;
      }
    });
  }

  async calclateOverallHrating() {
    this.overallHRating = await this.calculateAverage(this.points);
    console.log(this.overallHRating);
  }

  async calculateSectonOverallRatings() {

    let waterlinePoints = this.points.filter(x => x.pointLocation == "waterline");

    this.overallWaterlineRating = await this.calculateAverage(waterlinePoints);

    let flatsPoints = this.points.filter(x => x.pointLocation == "flats");

    this.overallFlatsRating = await this.calculateAverage(flatsPoints);

    let bowPoints = [];
    let shouldPoints = [];
    let midPoints = [];
    let aftPoints = [];
    this.transects.forEach(transect => {
      let transectPoints = this.points.filter(x => x.transectId == transect.id);
      switch (transect.shipLocation) {
        case "BOW":
          bowPoints = bowPoints.concat(transectPoints);
          break;
        case "SHOULDER":
          shouldPoints = shouldPoints.concat(transectPoints);
          break;
        case "MIDSHIP":
          midPoints = midPoints.concat(transectPoints);
        case "AFT":
          aftPoints = aftPoints.concat(transectPoints);
          break;
      }
    });
    this.overallBowRating = await this.calculateAverage(bowPoints);
    this.overallShoulderRating = await this.calculateAverage(shouldPoints);
    this.overallMidshipRating = await this.calculateAverage(midPoints);
    this.overallAftRating = await this.calculateAverage(aftPoints);
  }

  async calculateAverage(points) {
    let tempOv: number = 0;
    let countOv: number = 0;

    if (Array.isArray(points)) {
      points.forEach(point => {
        if (point.hRating) {
          tempOv += Number(point.hRating);
          countOv++;
        }
      });

      return tempOv / countOv;
    } else {
      return points.hRating
    }
  }

  async calculatePortHrating() {
    let portPoints = [];
    let bowPoints = [];
    let shouldPoints = [];
    let midPoints = [];
    let aftPoints = [];

    let waterlinePoint = [];
    let flatsPoints = [];

    this.transects.forEach(transect => {
      if (transect.shipSide == "port") {
        let transectPoints = this.points.filter(x => x.transectId == transect.id);
        portPoints =  portPoints.concat(transectPoints);
        if (Array.isArray(transectPoints)) {
          transectPoints.forEach(point => {
            if (point.pointLocation) {
              if (point.pointLocation == "waterline") {
                waterlinePoint.push(point);
              } else if (point.pointLocation == "flats") {
                flatsPoints.push(point);
              }
            }
          });
        }

        switch (transect.shipLocation) {
          case "BOW":
            bowPoints = bowPoints.concat(transectPoints);
            break;
          case "SHOULDER":
            shouldPoints = shouldPoints.concat(transectPoints);
            break;
          case "MIDSHIP":
            midPoints = midPoints.concat(transectPoints);
          case "AFT":
            aftPoints = aftPoints.concat(transectPoints);
            break;
        }
      }
    });

    this.overallPortRating = await this.calculateAverage(portPoints);
    this.portWaterlineRating = await this.calculateAverage(waterlinePoint);
    this.portFlatsRating = await this.calculateAverage(flatsPoints);
    this.portBowRating = await this.calculateAverage(bowPoints);
    this.portAftRating = await this.calculateAverage(aftPoints);
    this.portMidshipRating = await this.calculateAverage(midPoints);
    this.portShoulderRating = await this.calculateAverage(shouldPoints);
  }

  async calculateStarHrating() {
    let starPoints = [];
    let bowPoints = [];
    let shouldPoints = [];
    let midPoints = [];
    let aftPoints = [];

    let waterlinePoint = [];
    let flatsPoints = [];

    this.transects.forEach(transect => {
      if (transect.shipSide == "starboard") {
        let transectPoints = this.points.filter(x => x.transectId == transect.id);
        starPoints = starPoints.concat(transectPoints);
        if (Array.isArray(transectPoints)) {
          transectPoints.forEach(point => {
            if (point.pointLocation) {
              if (point.pointLocation == "waterline") {
                waterlinePoint.push(point);
              } else if (point.pointLocation == "flats") {
                flatsPoints.push(point);
              }
            }
          });
        }

        switch (transect.shipLocation) {
          case "BOW":
            bowPoints = bowPoints.concat(transectPoints);
            break;
          case "SHOULDER":
            shouldPoints = shouldPoints.concat(transectPoints);
            break;
          case "MIDSHIP":
            midPoints = midPoints.concat(transectPoints);
          case "AFT":
            aftPoints = aftPoints.concat(transectPoints);
            break;
        }
      }
    });

    this.overallStarboardRating = await this.calculateAverage(starPoints);
    this.starWaterlineRating = await this.calculateAverage(waterlinePoint);
    this.starFlatsRating = await this.calculateAverage(flatsPoints);
    this.starBowRating = await this.calculateAverage(bowPoints);
    this.starAftRating = await this.calculateAverage(aftPoints);
    this.starMidshipRating = await this.calculateAverage(midPoints);
    this.starShoulderRating = await this.calculateAverage(shouldPoints);
  }

  getFoulingPercentage(rating) {
    let percentage = (Number(rating) / 17) * 100;
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  }

  getStripSides() {
    this.transects.forEach(element => {
      console.log("here");
      this.stripsId = element;
      if (element.shipSide == 'starboard') {
        this.starboardSelectedStrips.push(element.stripId);
        this.stripIcons[element.stripId + "s"] = {
          icon1: element.featureProp != undefined ? element.featureProp : null,
          icon2: element.featureBilge != undefined ? element.featureBilge : null,
          icon3: element.featureChest != undefined ? element.featureChest : null,
          icon4: element.featureDraftmark != undefined ? element.featureDraftmark : null
        }
      } else if (element.shipSide == 'port') {
        this.portSelectedStrips.push(element.stripId);
        this.stripIcons[element.stripId + "p"] = {
          icon1: element.featureProp != undefined ? element.featureProp : null,
          icon2: element.featureBilge != undefined ? element.featureBilge : null,
          icon3: element.featureChest != undefined ? element.featureChest : null,
          icon4: element.featureDraftmark != undefined ? element.featureDraftmark : null
        }
      }
    });
  }

  getStripSide(transect){
    if(transect.shipside == 'starboard'){
      this.starboardSelectedStrips.push(transect.stripId);
      this.stripIcons[transect.stripId + "s"] = {
        icon1: transect.featureProp != undefined ? transect.featureProp : null,
        icon2: transect.featureBilge != undefined ? transect.featureBilge : null,
        icon3: transect.featureChest != undefined ? transect.featureChest : null,
        icon4: transect.featureDraftmark != undefined ? transect.featureDraftmark : null
      }
    } else if (transect.shipSide == 'port') {
      this.portSelectedStrips.push(transect.stripId);
      this.stripIcons[transect.stripId + "p"] = {
        icon1: transect.featureProp != undefined ? transect.featureProp : null,
        icon2: transect.featureBilge != undefined ? transect.featureBilge : null,
        icon3: transect.featureChest != undefined ? transect.featureChest : null,
        icon4: transect.featureDraftmark != undefined ? transect.featureDraftmark : null
      }
    }
  }

  getStripIcons(side, icon, strip) {
    try {
      if (icon == 1) {
        if (this.stripIcons[strip + side]['featureProp']) {
          return false;
        } else {
          return true
        }
      }
      if (icon == 2) {
        if (this.stripIcons[strip + side]['featureBilge']) {
          return false;
        } else {
          return true
        }
      }
      if (icon == 3) {
        if (this.stripIcons[strip + side]['featureChest']) {
          return false;
        } else {
          return true
        }
      }
      if (icon == 4) {
        if (this.stripIcons[strip + side]['featureDraftmark']) {
          return false;
        } else {
          return true
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  getStripDetails(strip, shipSide) {
    return this.stripDetail[strip + shipSide];
  }

  setupStrips(){
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });
  }

  setupStripDetailForTransect(transect){
    let strip = -1;
    let shipSide: string = null;

    shipSide = transect.shipSide;
    strip = transect.stripId;

    let stripName = strip + shipSide.trim().charAt(0);
    if (transect.featureProp) {
      this.stripIcons[stripName]['featureProp'] = true;
    }
    if (transect.featureBilge) {
      this.stripIcons[stripName]['featureBilge'] = true;
    }
    if (transect.featureChest) {
      this.stripIcons[stripName]['featureChest'] = true;
    }
    if (transect.featureDraftmark) {
      this.stripIcons[stripName]['featureDraftmark'] = true;
    }

    let pointsTransect = this.points.filter(x => x.transectId == transect.id);

    if (pointsTransect.length > 0) {
      pointsTransect.forEach(point => {
        let detailsPercentage = point['percentage']//(67*(point.data()['percentage']/100)) + 23;
        if (point.damage > 25) {
          this.stripDetail[stripName].push({ 'colour': '#000000', 'percentage': detailsPercentage });
        } else {
          this.stripDetail[stripName].push({ 'colour': point['colour'], 'percentage': detailsPercentage });
        }
      })
    }
  }

  setupStripDetail() {
    console.log("herr")
    this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });

    let strip = -1;
    let shipSide: string = null;

    //Go through each point for that transect get the information
    this.transects.forEach(transect => {
      shipSide = transect.shipSide;
      strip = transect.stripId;
      let stripName = strip + shipSide.trim().charAt(0);
      if (transect.featureProp) {
        this.stripIcons[stripName]['featureProp'] = true;
      }
      if (transect.featureBilge) {
        this.stripIcons[stripName]['featureBilge'] = true;
      }
      if (transect.featureChest) {
        this.stripIcons[stripName]['featureChest'] = true;
      }
      if (transect.featureDraftmark) {
        this.stripIcons[stripName]['featureDraftmark'] = true;
      }

      let pointsTransect = this.points.filter(x => x.transectId == transect.id);

      if (pointsTransect.length > 0) {
        pointsTransect.forEach(point => {
          let detailsPercentage = point['percentage']//(67*(point.data()['percentage']/100)) + 23;
          if (point.damage > 25) {
            this.stripDetail[stripName].push({ 'colour': '#000000', 'percentage': detailsPercentage });
          } else {
            this.stripDetail[stripName].push({ 'colour': point['colour'], 'percentage': detailsPercentage });
          }
        })
      }
    })
  }

  saveGeneratedReport(){
    //The the recent generation of the report for the future
    let id = this.db.createId();
    let date = Date.now();
    this.reportId = id;
    this.db.collection('report').doc(id).set({
      id: id,
      inspectionId: this.inspection.id,
      inspectorString: this.inspectorString,
      overallHRating: this.overallHRating,
      overallBowRating: this.overallBowRating,
      overallShoulderRating: this.overallShoulderRating,
      overallMidshipRating: this.overallMidshipRating,
      overallAftRating: this.overallAftRating,
      overallWaterlineRating: this.overallWaterlineRating,
      overallFlatsRating: this.overallFlatsRating,
      overallPortRating: this.overallPortRating,
      portBowRating: this.portBowRating,
      portShoulderRating: this.portShoulderRating,
      portMidshipRating: this.portMidshipRating,
      portAftRating: this.portAftRating,
      portWaterlineRating: this.portWaterlineRating,
      portFlatsRating: this.portFlatsRating,
      overallStarboardRating: this.overallStarboardRating,
      starBowRating: this.starBowRating,
      starShoulderRating: this.starShoulderRating,
      starMidshipRating: this.starMidshipRating,
      starAftRating: this.starAftRating,
      starWaterlineRating: this.starWaterlineRating,
      starFlatsRating: this.starFlatsRating,
      coatingBoot: this.coatingBoot,
      coatingVert: this.coatingVert,
      coatingFlat: this.coatingFlat,
      visibility: this.visibility,
      weatherstring: this.weatherString,
      vessel: this.vessel,
      vesselTypePic: this.vesselTypePic,
      starboardSelectedStrips: this.starboardSelectedStrips,
      portSelectedStrips: this.portSelectedStrips,

      date: date,
    });
  }

  saveUpdatedReport(){
    this.db.collection('report').doc(this.reportId).update({
      bowComments: this.bowComments ? this.bowComments : null,
      shoulderComments: this.shoulderComments ? this.shoulderComments : null,
      midshipComments: this.midshipComments ? this.midshipComments : null,
      aftComments: this.aftComments ? this.aftComments : null,
      propComments: this.propComments ? this.propComments : null,
      rudComments: this.rudComments ? this.rudComments : null,
      waterlineComments: this.waterlineComments ? this.waterlineComments : null,
      flatsComments: this.flatsComments ? this.flatsComments : null,
      actions: this.actions ? this.actions : null,
    }).then(() => {
      this.toastr.success('Report Successfully Saved', 'Saved')
    }).catch(() => {
      this.toastr.error('Something went wrong. Please try again later.', 'Saved Failed')
    })
  }

  saveNewGeneratedReport(){
    let date = Date.now();
    this.db.collection('report').doc(this.reportId).update({
      bowComments: this.bowComments,
      shoulderComments: this.shoulderComments,
      midshipComments: this.midshipComments,
      aftComments: this.aftComments,
      propComments: this.propComments,
      rudComments: this.rudComments,
      actions: this.actions,
      waterlineComments: this.waterlineComments,
      flatsComments: this.flatsComments,
      inspectorString: this.inspectorString,
      overallHRating: this.overallHRating,
      overallBowRating: this.overallBowRating,
      overallShoulderRating: this.overallShoulderRating,
      overallMidshipRating: this.overallMidshipRating,
      overallAftRating: this.overallAftRating,
      overallWaterlineRating: this.overallWaterlineRating,
      overallFlatsRating: this.overallFlatsRating,
      overallPortRating: this.overallPortRating,
      portBowRating: this.portBowRating,
      portShoulderRating: this.portShoulderRating,
      portMidshipRating: this.portMidshipRating,
      portAftRating: this.portAftRating,
      portWaterlineRating: this.portWaterlineRating,
      portFlatsRating: this.portFlatsRating,
      overallStarboardRating: this.overallStarboardRating,
      starBowRating: this.starBowRating,
      starShoulderRating: this.starShoulderRating,
      starMidshipRating: this.starMidshipRating,
      starAftRating: this.starAftRating,
      starWaterlineRating: this.starWaterlineRating,
      starFlatsRating: this.starFlatsRating,
      coatingBoot: this.coatingBoot,
      coatingVert: this.coatingVert,
      coatingFlat: this.coatingFlat,
      visibility: this.visibility,
      weatherstring: this.weatherString,
      vessel: this.vessel,
      vesselTypePic: this.vesselTypePic,
      starboardSelectedStrips: this.starboardSelectedStrips,
      portSelectedStrips: this.portSelectedStrips,

      date: date,
    })
  }

  async print(){
    this.loadingService.setLoadingScreen(true);
    //hide print and generate buttons
    //save report buttons and interactive report buttons
    let printContents = document.getElementById('report-container');
    let buttons = document.getElementsByClassName('hide-print');
    let propSection = document.getElementById('propRud');
    let title = document.getElementById('title');
    title.classList.add('padding-left');
    propSection.classList.add('padding-top');
    for(let i = 0; i < buttons.length; i++){
      let button = <HTMLElement> buttons.item(i);
      button.classList.add('hide');
    }
    var opt = {
      jsPDF: {unit: 'pt',orientation: 'landscape', format: 'legal'},
      image: {type: 'PNG', quality: 1},
      html2canvas: {scale: 1.0, allowTaint: true, scrollX: -window.scrollX, scrollY: -window.scrollY, useCORS: true, height: 3851, width: 1624, windowWidth: 1624, windowHeight: 3851},
      pagebreak: {
        mode: ['avoid-all', 'css']
      },
      filename: this.inspection.date + "-" + this.inspection.vesselName,
      margin: 15
    }
    //await html2pdf(printContents, opt);
    //await html2pdf.from(printContents).set(opt).save();
    let pdfName = this.inspection.date + "-" + this.inspection.vesselName;


    await html2canvas(printContents, {
      allowTaint: true,
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY
    }).then(canvas => {
      let HTML_Width = 1624;
      let HTML_Height = 3851;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/png", 1.0);
      let pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }

      propSection.classList.remove('padding-top');
      title.classList.remove('padding-left');
      for(let i = 0; i < buttons.length; i++){
        let button = <HTMLElement> buttons.item(i);
        button.classList.remove('hide');
      }
      this.loadingService.setLoadingScreen(false);
      pdf.save(pdfName + ".pdf");
    })
  }
}
