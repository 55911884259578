import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private AuthService: AuthServiceService,private router: Router,
    private loadingService: LoadingService,
    private afAuth: AngularFireAuth) { }

  forgotPwShow 		 = false;
  resetScreen		   = false;
  username 			   = null;
  userpw			     = null;
  errMsg			     = '';

  ngOnInit() {
    this.loadingService.setLoadingScreen(false);
  }

  public forgotPwScreen(): void {
  	if(!this.forgotPwShow) {
  		this.forgotPwShow = true;
      this.errMsg       = '';
  	} else {
  		this.forgotPwShow = false;
  	}
  }

  public login(): void {
    this.afAuth.signInWithEmailAndPassword(this.username, this.userpw).then(val => {
      this.loadingService.setLoadingScreen(true);
	  	this.errMsg = '';
      setTimeout(() => {
        this.router.navigate(['/overview/']);
        localStorage.setItem('intSection', 'true');
      }, 2000);
    }).catch(err => {
      this.errMsg = "Invalid Username / Password :(";
    });
  }

  //Enter key listener
  public onKeydown(event) {
    this.login();
  }


  public resetPassword(): void {
    this.afAuth.sendPasswordResetEmail(this.username).then(value => {
      this.forgotPwShow	= false;
      this.resetScreen 	= true;
    });
  }


}
