import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, of, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../loading.service';
import { HeaderService } from '../service/header.service';
import { VimeoUploadService } from '../vimeo-upload.service';
import { VimeoService } from '../vimeo.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  foulingColours      = [];
  screenshots         = [];
  damagedMarkers      = [];
  currentStrip        = null;

  selectedIcons       = [];

  draftInput  = 0;
  depthInput = 0;

  draftNumbers = [];
  depthNumbers = [];

  stripIcons = [];

  screenshotToast: number;

  disableScreenshot: boolean = false;

  levelDepth             = [];

  stripsId               = [];
  id                     = null;

  strips                   = [];
  portSelectedStrips       = [];
  starboardSelectedStrips  = [];

  content             = "details";

  screenShotActive      = false;
  screenShotId        = [];

  currentScreenshotId  = null;
  currentFoulingId      = null;

  mouseDown            = false;

  foulingRatings        = [0, 0, 0, 0];

  vesselDb                     = null;

  damaged             = false;

  damageRating = 0;


  photoLink             = null;
  photo                 = "./../../../assets/images/vessel-photo.png";

  starboard           = true;


  stripDetail         = [];

  videoUploadOpen          = false;

  fileToUpload: File       = null;

  videoLink                = this.vimeoUpload.getVideoLink();

  vesselName          = null;
  port                = null;
  caseDate            = null;

  vesselLength        = 0;
  inspectionDepth     = 0;

  vesselType          = null;

  inspectionId = null;
  inspection = null;
  vessel = {name: ''};

  selectedIcon = null;

  pointLocation = null;
  transects = [];

  flats: boolean = false;
  waterline: boolean = false;

  newScreenshot: boolean = false;

  refreshEnabled: boolean = false;

  private element: HTMLElement;

  constructor(public vimeoUpload: VimeoUploadService,
    private db: AngularFirestore,
    public vimeo: VimeoService,
    private router: Router,
    private toastr: ToastrService,
    private afStorage: AngularFireStorage,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.headerService.CurrentPage = "inspection";
    this.transects = [];
    this.currentStrip = null;
    this.levelDepth = Array(11).fill(1,0,11).map((x,i)=>i);
    this.loadingService.setLoadingScreen(true);
    this.route.params.subscribe(params => {
      if(params.id){
        this.inspectionId = params.id;
        this.route.queryParams.subscribe(qParams => {
          if(qParams['strip']){
            if(qParams['side']){
              let strip = Number(qParams['strip']);
              this.db.collection('transect').ref.where('stripId', '==', strip).where('shipSide', '==', qParams['side'])
                .where('inspectionId', '==', this.inspectionId).get().then(res => {
                  if(!res.empty){
                    if(res.size == 1){
                      res.forEach(tra => {
                        this.currentStrip = tra.data();
                        this.id = this.currentStrip.id;
                        this.starboard = this.currentStrip.shipSide == "starboard" ? true : false;
                        this.checkForScreenshots();
                        this.checkForDepthMarkers();
                        this.checkForVideo();
                      });
                      this.loadingService.setLoadingScreen(false);
                    }else{
                      this.toastr.error('Unknown Error. Please try again', 'Unknown Error')
                      this.router.navigate(['transect'], {queryParams: {id: this.inspectionId}});
                    }
                  }else{
                    this.toastr.error('Please try again.', 'Something went wrong.');
                    this.loadingService.setLoadingScreen(false);
                    this.router.navigate(['transect'], {queryParams: {id: this.inspectionId}});
                  }
                })
              this.getAllTransects();
              this.db.collection('inspection').doc(this.inspectionId).get().subscribe(inspection => {
                if(inspection.exists){
                  this.inspection = inspection.data();
                  this.db.collection('vessel').doc(this.inspection.imo.trim()).get().subscribe(res => {
                    if(res.exists){
                      //@ts-ignore
                      this.vessel = res.data();
                      console.log(this.vessel)
                      this.db.collection('port').doc(this.inspection.port.trim()).get().subscribe(resPort => {
                        if(resPort.exists){
                          this.port = resPort.get('name');
                        }
                      })
                    }else{
                      this.toastr.error('Vessel does not exist.', 'Vessel Error');
                    }
                  })
                }
              })
            }
          }
        })
      }else{
        this.toastr.error('The inspection does not exist.', 'Inspection not found')
        this.router.navigate(['overview']);
      }
    })
  }

  getAllTransects(){
    this.transects = [];
    this.db.collection('transect').ref.where('inspectionId', '==', this.inspectionId).get().then(res => {
      if(!res.empty){
        res.forEach(re => {
          this.transects.push(re.data());
        })
        this.sortTransects();
      }
    })
  }

  getFoulingTotal(){
    return Number(this.foulingRatings[0]) + Number(this.foulingRatings[1]) + Number(this.foulingRatings[2]) + Number(this.foulingRatings[3]);
  }
  checkLocation(waterline: boolean, event){
    if(waterline && this.flats && this.waterline){
      this.flats = false
    }
    if(!waterline && this.waterline && this.flats){
      this.waterline = false;
    }
  }

  sortTransects(){
    this.transects.sort((a, b) => Number(a.stripId) - Number(b.stripId));
  }

  initDrag(): void{
    const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(this.document, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.document, "mousemove").pipe(
      takeUntil(dragEnd$)
    );

    let initialY: number,
      currentY = 0;

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialY = event.clientY - currentY;

      dragSub = drag$.subscribe((event: MouseEvent) => {
        this.mouseDown = true;
        currentY = event.clientY - initialY;
        if(currentY < 100 && currentY > -1 ){
          this.element.style.top = currentY+"%";
        }
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      if(this.mouseDown){
        initialY = currentY;
        if(currentY < 100 && currentY > -1 ){
          this.setCurrentTime(currentY);
          this.vimeo.videoUpdate = currentY;
        }
        this.mouseDown = false;
      }
      if(dragSub){
        dragSub.unsubscribe();
      }
    });
  }

  setCurrentTime(currentY: number){
    let timestamp = (currentY/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  async validateWaterline(){
    return await this.db.collection('point').ref.where('transectId', '==', this.id).get().then((docs: any) => {
      if(!docs.empty){
        let waterline = true;
        docs.forEach(doc => {
          if(doc.data().pointLocation == "waterline"){
            waterline = false;
          }
        });
        return waterline;
      }else{
        return false;
      }
    });
  }

  async validateFlats(){
    return await this.db.collection('point').ref.where('transectId', '==', this.id).get().then((docs: any) => {
      if(!docs.empty){
        let waterline = true;
        docs.forEach(doc => {
          if(doc.data().pointLocation == "flats"){
            waterline = false;
          }
        });
        return waterline;
      }else{
        return false;
      }
    });
  }

  getNumber(string){
    return parseInt(string)
  }

  async nextStrip(){
    if(this.transects.length > 1){
      if(await this.validateWaterline() && await this.validateFlats()){
        this.toastr.warning('You have no waterline or flats selected.', 'Warning')
      }else if(await this.validateFlats()){
        this.toastr.warning('You have no flats selected.', 'Warning')
      }else if(await this.validateWaterline()){
        this.toastr.warning('You have no waterline selected.', 'Warning')
      }
      let currentIndex = this.transects.findIndex(x => x.stripId == this.currentStrip.stripId);

      if(currentIndex == this.transects.length - 1){
        this.currentStrip = null;
        this.loadingService.setLoadingScreen(true);
        this.router.navigate(['detail', this.inspectionId], {queryParams: {strip: this.transects[0].stripId, side: this.transects[0].shipSide}})
      }else{
        this.currentStrip = null;
        this.loadingService.setLoadingScreen(true);
        this.router.navigate(['detail', this.inspectionId], {queryParams: {strip: this.transects[currentIndex+1].stripId, side: this.transects[currentIndex+1].shipSide}})
      }
    }
  }

  async previousStrip(){
    if(this.transects.length > 1){
      if(await this.validateWaterline() && await this.validateFlats()){
        this.toastr.warning('You have no waterline or flats selected.', 'Warning')
      }else if(await this.validateFlats()){
        this.toastr.warning('You have no flats selected.', 'Warning')
      }else if(await this.validateWaterline()){
        this.toastr.warning('You have no waterline selected.', 'Warning')
      }
      let currentIndex = this.transects.findIndex(x => x.stripId == this.currentStrip.stripId);

      if(currentIndex == 0){
        this.currentStrip = null;
        this.loadingService.setLoadingScreen(true);
        this.router.navigate(['detail', this.inspectionId], {queryParams: {strip: this.transects[this.transects.length-1].stripId, side: this.transects[this.transects.length-1].shipSide}})
      }else{
        this.currentStrip = null;
        this.loadingService.setLoadingScreen(true);
        this.router.navigate(['detail', this.inspectionId], {queryParams: {strip: this.transects[currentIndex-1].stripId, side: this.transects[currentIndex-1].shipSide}});
      }
    }
  }

  // inspectionStrip(strip, shipSide): void{
  //   if(shipSide == "starboard"){
  //     let tempStrip = this.starboardSelectedStrips.find(x => x == strip);
  //     this.stripsId.forEach(element => {
  //       if(element['shipside'] == "starboard"){
  //         if(element['strip'] == tempStrip){
  //           this.currentStrip = element;
  //         }
  //       }
  //     });
  //   }else{
  //     let tempStrip = this.portSelectedStrips.find(x => x == strip);
  //     this.stripsId.forEach(element => {
  //       if(element['shipside'] == "port"){
  //         if(element['strip'] == tempStrip){
  //           this.currentStrip = element;
  //         }
  //       }
  //     });
  //   }
  //   this.content = "upload";
  //   this.checkForVideo();
  //   this.checkForScreenshots();
  //   this.checkForDepthMarkers();
  //   this.vimeo.pauseVideo();
  // }

  async checkForVideo(){
    setTimeout(() => {
      this.refreshEnabled = true;
    }, 30000)
    await this.vimeo.removeVideo();
    if(!this.currentStrip.videoLink){
      this.vimeoUpload.setUploaded(false);
    }else if(this.currentStrip.videoLink != null){
      this.vimeoUpload.setUploaded(true);
      await this.vimeo.loadVideo(this.currentStrip.videoLink);
    }
  }

  checkForDepthMarkers(){
    this.draftNumbers = [];
    this.depthNumbers = [];

    this.db.collection('point').ref.where('transectId', '==', this.id).get().then(docs => {
      if(!docs.empty){
        docs.forEach(elm => {
          let depth = Number(elm.data()['depth']);
          this.depthNumbers.push({depth: depth, value: elm.data()['depthValue'], id: elm.data()['id']});
          this.draftNumbers.push({depth: depth, value: elm.data()['draftValue'], id: elm.data()['id']});
        })
      }
    });
  }

  checkForScreenshots(){
    this.screenShotId = [];
    let length = this.screenShotId.length-1;
    this.foulingColours = [];
    this.damagedMarkers = [];

    this.screenshots = [];

    this.screenShotActive = false;
    this.currentScreenshotId = null;

    this.db.collection('point').ref.where('transectId', '==', this.id).get().then((docs: any) => {
      if(!docs.empty){
        docs.forEach(doc => {
          if(doc.data().colour){
            this.foulingColours.push({'colour': doc.data()['colour'], 'depth': doc.data()['depth']-1.83});
          }
          if(doc.data()['damage'] > 25){
            this.damagedMarkers.push({'depth': doc.data()['depth'] -0.43, 'screenshot': doc.id});
          }
          this.generateScreenshotIconWTime(doc.data()['depth'], doc.data()['videoTime'], doc.id, doc.id);
        })
      }
    })
  }


  setValuesToZero(){
    for(var i = 0; i < 4; i++){
      this.foulingRatings[i] = 0;
    }
    this.damageRating = 0;
  }


  async createScreenshot(){
    this.disableScreenshot = true;
    this.newScreenshot = true;
    this.screenshotToast = this.toastr.info("Generating your snapshot now...", "Please Wait!", {
      disableTimeOut: true
    }).toastId;

    this.vimeo.pauseVideo();

    let thumbnailLink = null;

    await this.vimeo.createThumbnail().then((val) => {
      let sizes = [];
      sizes = val['sizes'];
      thumbnailLink = sizes[sizes.length-1]['link'];
    });

    await this.transferScreenshot(thumbnailLink).then(data => {
      thumbnailLink = data;
    });

    console.log(thumbnailLink);

    let currentVideoTime = 0;
    await this.vimeo.getCurrentTime().then((val) => {
      currentVideoTime = val;
    });

    let id = this.db.createId();

    this.db.collection('point').doc(id).set({
      transectId: this.id,
      videoTime: currentVideoTime,
      id: id,
      pictureLink: thumbnailLink,
      sRating: 0,
      wRating: 0,
      aRating: 0,
      a2Rating: 0,
      damage: 0,
      depth: this.vimeo.videoUpdate
    })
    this.generateScreenshotIcon(id, currentVideoTime);
  }

  async transferScreenshot(thumbnailLink){
    let download = this.downloadFile(thumbnailLink);
    return download.then(dl => {
      return this.uploadToFirebase(dl, this.starboard, this.inspection.id, this.id);
    })
  }

  uploadToFirebase(blob: Blob, starboard, inspectionId, transectId){
    let number = Math.floor((Math.random() + 1) * 100000);
    let shipSide = starboard == true ? "starboard" : "port";
    let path = inspectionId + "/" + transectId + "/" + shipSide + "/" + number;
    return this.afStorage.upload(path, blob).then(val => {
      return this.afStorage.ref(path).getDownloadURL().toPromise()
    })
  }

  downloadFile(thumbnailLink){
    return fetch(thumbnailLink)
    .then(this.processStatus)
    .then(this.parseBlob)
  }

  processStatus(response){
    if (response.status === 200 || response.status === 0) {
      return Promise.resolve(response)
    }
    else {
      return Promise.reject(new Error(`Error loading`))
    }
  }

  parseBlob(response) {
    return response.blob()
  }

  parseJson(response) {
    return response.json()
  }

  deleteScreenshot(){
    //Remove from arrays
    console.log(this.currentScreenshotId);
    let id = this.currentScreenshotId.screenshot;
    let idIndex = this.screenShotId.findIndex(x => x.screenshot == id);
    let index = this.screenshots.findIndex(x => x.length == idIndex);
    this.screenShotId.splice(idIndex, 1);
    this.screenshots.splice(index, 1);
    //Remove from firebase
    this.db.collection('point').doc(id).delete();
    //Delete the screenshot
    this.screenShotActive = false;
    this.setValuesToZero();
    this.currentScreenshotId = null;
    this.damaged = false;
    this.selectedIcons = [];
    this.newScreenshot = false;
  }

  async generateScreenshotIcon(screenshotId, currentVideoTime){
    this.depthInput = 0;
    this.draftInput = 0;

    this.screenShotId.push({screenshot: screenshotId});
    let length = this.screenShotId.length-1;

    this.screenshots.push({"videoTime": currentVideoTime, "videoUpdate": this.vimeo.videoUpdate, "length": length});

    this.screenShotActive = true;
    this.currentScreenshotId = this.screenShotId[length];
    this.toastr.remove(this.screenshotToast);
    this.disableScreenshot = false;
  }

  async generateScreenshotIconWTime(videoUpdate, videoTime, screenshotId, foulingId){
    this.screenShotId.push({screenshot: screenshotId});
    let length = this.screenShotId.length-1;

    this.screenshots.push({"videoTime": videoTime, "videoUpdate": videoUpdate, "length": length});
  }

  getScreenshot(timestamp, videoUpdate, index){
    this.selectedIcons = [];
    this.vimeo.pauseVideo();
    //Apply video time
    this.vimeo.changeVideoTime(timestamp);
    //move bar
    this.vimeo.setVideoUpdate(videoUpdate);

    this.currentScreenshotId = this.screenShotId[index];

    this.db.collection('point').doc(this.currentScreenshotId['screenshot']).get().subscribe((point: any) => {
      if(point.exists){
        this.damaged = point.data().damage > 25 ? true : false;
        this.damageRating = point.data().damage;
        if(point.data().featureProp){
          this.selectedIcon = "featureProp"
        }
        if(point.data().featureBilge){
          this.selectedIcon = "featureBilge"
        }
        if(point.data().featureChest){
          this.selectedIcon = "featureChest"
        }
        if(point.data().featureDraftmark){
          this.selectedIcon = "featureDraftmark"
        }
        if(point.data().featureRudder){
          this.selectedIcon = "featureRudder"
        }

        this.flats = point.data().pointLocation == "flats" ? true : false;
        this.waterline = point.data().pointLocation == "waterline"  ? true : false;

        this.depthInput = point.data().depthValue;
        this.draftInput = point.data().draftValue;

        this.setFoulingValues( point.data()['sRating'], point.data()['wRating'], point.data()['aRating'], point.data()['a2Rating']);
      }
    })
    this.screenShotActive = true;
  }

  setFoulingValues(s, w, a, a2){
    this.foulingRatings[0] = s;
    this.foulingRatings[1]= w;
    this.foulingRatings[2]= a;
    this.foulingRatings[3] = a2;
  }

  uploadPhoto(file: FileList){
    let toastId = this.toastr.info("Please wait while the image is uploaded...", "Uploading!", {
      disableTimeOut: true
    }).toastId;
    const fileToUpload = file.item(0);
    if(fileToUpload.type.split('/')[0] === 'image'){
      const path = `vessel/${new Date().getTime()}_${fileToUpload.name}`;
      let ref = this.afStorage.ref(path);

      let task = this.afStorage.upload(path, fileToUpload).then(val => {
        this.afStorage.ref(path).getDownloadURL().subscribe(data => {
          this.photo = data;

        });
      }).then(() => this.toastr.remove(toastId)).catch(() => {
        this.toastr.error("There was a problem uploading the image, please try again.", "Upload Error!");
      });
    }
  }

  getSliderPercentage(slider){
    if(slider == "damage"){
      return this.damageRating;
    }else{
      return this.foulingRatings[slider];
    }
  }

  setSliderPercentage(slider, value){
    if(slider == "damage"){
      this.damageRating = value;
      console.log(value);
    }else{
      this.foulingRatings[slider] = value;
    }
    return value;
  }

  async saveFoulingRating(){
    if(isNaN(this.depthInput) || isNaN(this.draftInput)){
      this.toastr.error("Please make sure your depth and draft inputs are numbers.", "Input Error!")
      return;
    }

    if(this.depthInput > 999 || this.draftInput > 999){
      this.toastr.error("Your depth or draft number is invalid.", "Input Error!")
      return;
    }

    if(this.inspection.draft < (Number(this.draftInput) + Number(this.depthInput))){
      this.toastr.error("Your draft and depth numbers are invalid compared to your draft at waterline number", 'Input Error!')
      return;
    }

    if((Number(this.foulingRatings[0]) + Number(this.foulingRatings[1]) + Number(this.foulingRatings[2]) + Number(this.foulingRatings[3]) ) > 100){
      this.toastr.error("Your fouling levels have exceeded 100%, please try again", "Fouling Error");
      return;
    }
    let colour = null;

    this.depthNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.depthNumbers.splice(this.depthNumbers.indexOf(elm), 1);
      }
    });

    this.draftNumbers.forEach(elm => {
      if(elm['id'] == this.currentScreenshotId['screenshot']){
        this.draftNumbers.splice(this.draftNumbers.indexOf(elm), 1);
      }
    });

    let hRating = (this.foulingRatings[0] * 0.08) + (this.foulingRatings[1] * 0.1) + (this.foulingRatings[3] * 0.25) + (this.foulingRatings[2] * 0.4);

    if(hRating < 7 && hRating > 0){
      colour = "#1dff00";
    }else if(hRating >= 7 && hRating < 12){
      colour = '#FFC400';
    }else if(hRating >= 12){
      colour = '#FF0700';
    }


    let currentVideoTime = await this.vimeo.getCurrentTime();

    let percentage = (currentVideoTime/this.vimeo.duration)*100;

    this.foulingColours.push({'colour': colour, 'depth': this.vimeo.videoUpdate-1.83});

    if(this.damageRating > 25){
      this.damagedMarkers.push({'depth': this.vimeo.videoUpdate-0.43, 'screenshot': this.currentScreenshotId['screenshot']});
    }else{
      this.damagedMarkers.forEach(damage => {
        if(damage['screenshot'] == this.currentScreenshotId['screenshot']){
          const index = this.damagedMarkers.indexOf(damage, 0);
          this.damagedMarkers.splice(index, 1);
        }
      })
    }

    let icon1 = false;
    let icon2 = false;
    let icon3 = false;
    let icon4 = false;
    let icon5 = false;

    if(this.selectedIcon != null){
      if(this.selectedIcon == "featureProp"){
        icon1 = true;
      }else if(this.selectedIcon == "featureBilge"){
        icon2 = true;
      }else if(this.selectedIcon == "featureChest"){
        icon3 = true;
      }else if(this.selectedIcon == "featureDraftmark"){
        icon4 = true;
      }else if(this.selectedIcon == "featureRudder"){
        icon5 = true;
      }
    }

    let pointLocationTemp = null;
    if(!this.flats && !this.waterline){
      pointLocationTemp = "verticals";
    }else if(this.flats){
      pointLocationTemp = "flats";
    }else{
      pointLocationTemp = "waterline";
    }

    if(this.depthInput && this.draftInput){
      this.db.collection('point').doc(this.currentScreenshotId['screenshot']).update({
        sRating: this.foulingRatings[0],
        wRating: this.foulingRatings[1],
        aRating: this.foulingRatings[2],
        a2Rating: this.foulingRatings[3],
        colour: colour,
        percentage: percentage,
        damage: this.damageRating,
        featureProp: icon1,
        featureBilge: icon2,
        featureChest: icon3,
        featureDraftmark: icon4,
        featureRudder: icon5,
        depthValue: this.depthInput,
        draftValue: this.draftInput,
        pointLocation: pointLocationTemp,
        hRating: hRating
      })

      if(!this.currentStrip.featureProp && icon1){
        this.db.collection('transect').doc(this.id).update({featureProp: true}).then(() => {
          this.currentStrip.featureProp
        })
      }
      if(!this.currentStrip.featureBilge && icon2){
        this.db.collection('transect').doc(this.id).update({featureBilge: true}).then(() => {
          this.currentStrip.featureBilge
        })
      }
      if(!this.currentStrip.featureChest && icon3){
        this.db.collection('transect').doc(this.id).update({featureChest: true}).then(() => {
          this.currentStrip.featureChest
        })
      }
      if(!this.currentStrip.featureDraftmark && icon4){
        this.db.collection('transect').doc(this.id).update({featureDraftmark: true}).then(() => {
          this.currentStrip.featureDraftmark
        })
      }
      if(!this.currentStrip.featureRudder && icon4){
        this.db.collection('transect').doc(this.id).update({featureRudder: true}).then(() => {
          this.currentStrip.featureRudder
        })
      }


      /* this.vesselDb.collection('inspectionPoint').doc(this.currentStrip['id']).collection('screenshot').doc(this.currentScreenshotId['screenshot']).collection('fouling').doc(this.currentScreenshotId['fouling']).update({sRating: this.foulingRatings[0], wRating: this.foulingRatings[1], aRating: this.foulingRatings[2], a2Rating: this.foulingRatings[3], colour: colour, percentage: percentage, damaged: this.damaged,
        icon1: icon1, icon2: icon2, icon3: icon3, icon4: icon4, depthValue: this.depthInput, draftValue: this.draftInput}); */

        let shipSide = this.starboard ? 's' : 'p';

      let strip = this.currentStrip['strip']+shipSide;

      this.stripDetail[strip] = [];

      let detailsPercentage = (67*(percentage/100)) + 23;

      this.stripDetail[strip].push({'colour': colour, 'percentage': detailsPercentage});

      this.depthNumbers.push({depth: this.vimeo.videoUpdate, value: this.depthInput, id: this.currentScreenshotId['screenshot']});
      this.draftNumbers.push({depth: this.vimeo.videoUpdate, value: this.draftInput, id: this.currentScreenshotId['screenshot']});
      this.getStripDetail();

      this.toastr.success('Fouling Levels have successfully been saved.', 'Save Successful')
    }else{
      this.toastr.error('You must enter depth and draft values.', 'Value Error')
    }

  }

  getStripDetail(){
    /*     this.strips.forEach(element => {
      this.stripDetail[element + 's'] = [];
    });

    this.strips.forEach(element => {
      this.stripDetail[element + 'p'] = [];
    });

    let strip = -1;
    let shipSide = null;
    this.db.collection('vessel').doc(this.id).collection('inspectionPoint').get().subscribe(data => {
      if(data.docs){
        data.docs.forEach(elm => {
          this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').get().subscribe(screenshot => {
            if(screenshot.docs){
              screenshot.docs.forEach(screenshotElmt => {
                this.db.collection('vessel').doc(this.id).collection('inspectionPoint').doc(elm.data()['id']).collection('screenshot').doc(screenshotElmt.data()['id']).collection('fouling').get().subscribe(val => {
                  if(val.docs){
                    val.docs.forEach(foulingVl => {
                      shipSide = elm.data()['shipside'];
                      strip = elm.data()['strip'];
                      if(shipSide == "starboard"){
                        let stripName = strip+"s";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage});
                        }
                      }else{
                        let stripName = strip+"p";
                        let detailsPercentage = (67*(foulingVl.data()['percentage']/100)) + 23;
                        if(foulingVl.data()['damaged']){
                          this.stripDetail[stripName].push({'colour': '#000000', 'percentage': detailsPercentage});
                        }else{
                          this.stripDetail[stripName].push({'colour': foulingVl.data()['colour'], 'percentage': detailsPercentage});
                        }
                      }
                    });
                  }
                });
              });
            }
          });
        });
      }
    }); */
  }

  screenshotActive(index){
    if(this.currentScreenshotId != null){
      return this.currentScreenshotId == this.screenShotId[index];
    }
    return false;
  }

  getStripDetails(strip, shipSide){
    return this.stripDetail[strip + shipSide];
  }

  setDamaged(){
    this.damaged = !this.damaged;
  }

  selectIcon(icon: number){
    if(this.selectedIcons.includes(icon)){
      this.selectedIcons.splice(this.selectedIcons.indexOf(icon), 1);
    }else{
      this.selectedIcons.push(icon);
    }
  }

  iconSelected(icon: number){
    return this.selectedIcons.includes(icon) ? true : false;
  }

  getStripIcons(side, icon, strip){
    try{
      if(icon == 1){
        return !this.stripIcons[strip + side]['icon1'];
      }
      if(icon == 2){
        return !this.stripIcons[strip + side]['icon2'];
      }
      if(icon == 3){
        return !this.stripIcons[strip + side]['icon3'];
      }
      if(icon == 4){
        return !this.stripIcons[strip + side]['icon4'];
      }
      return true;
    }catch(err){
      return false;
    }
  }

  srubVideo(value){
    let timestamp = (value/100) * this.vimeo.duration;
    this.vimeo.changeVideoTime(timestamp);
  }

  refresh(){
    location.reload();
  }

  changeVideoUploadOpen(){
    this.videoUploadOpen = !this.videoUploadOpen;
  }

  backToOverview(){
    if(this.validateWaterline() && this.validateFlats()){
      this.toastr.warning('You have no waterline or flats selected in a strip', 'Reminder')
    }else if(this.validateFlats()){
      this.toastr.warning('You have no flats selected in a strip', 'Reminder')
    }else if(this.validateWaterline()){
      this.toastr.warning('You have no waterline selected in a strip', 'Reminder')
    }
    this.router.navigate(['transect'], {queryParams: {id: this.inspectionId}});
  }

  uploadFile(files: FileList){
    this.fileToUpload = files.item(0);
    this.vimeoUpload.setloading(true);
    let shipSide = this.starboard ? "starboard" : "port"
    this.videoLink = this.vimeoUpload.uploadToVimeo(this.fileToUpload, this.vessel.name, this.currentStrip, shipSide, this.id);
  }

  pauseVideo(){
    let pauseButton = document.getElementById("pause");
    this.vimeo.pauseVideo();
  }

  playVideo(){
    let pauseButton = document.getElementById("pause");
    this.screenShotActive = false;
    this.setValuesToZero();
    this.currentScreenshotId = null;
    this.vimeo.playVideo();
    this.damaged = false;
    this.selectedIcons = [];
    this.newScreenshot = false;
  }

  playOrPause(){
    if(this.vimeo.paused){
      this.playVideo();
    }else{
      this.pauseVideo();
    }
  }
}
