import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../loading.service';
import { HeaderService } from '../service/header.service';

@Component({
  selector: 'app-vessel',
  templateUrl: './vessel.component.html',
  styleUrls: ['./vessel.component.scss']
})
export class VesselComponent implements OnInit {

  vesselName = null;
  vesselLength = 0;
  vesselType = null;
  imo = null;

  customers = [];
  vesselTypes = [];

  customerSelected = null;

  vesselId: string = null;

  photo = "./../../../assets/images/vessel-photo.png";

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private afStorage: AngularFireStorage,
    private router: Router,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private headerService: HeaderService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.headerService.CurrentPage = "vessel";
    await this.route.queryParams.subscribe(params => {
      if(params['id']){
        this.vesselId = params['id'];
        console.log(this.vesselId);
        this.db.collection('vessel').doc(this.vesselId).get().subscribe(vessel => {
          if(vessel.exists){
            this.imo = vessel.get('imo');
            this.vesselId = vessel.get('imo');
            this.vesselName = vessel.get('name');
            this.vesselType = vessel.get('type');
            this.vesselLength = vessel.get('length');
            this.photo = vessel.get('photo') ? vessel.get('photo') : "./../../../assets/images/vessel-photo.png";
            this.customerSelected = vessel.get('customer').name;
          }
        })
      }
    })
    this.db.collection('vesselType').ref.orderBy('name').get().then(types => {
      if(!types.empty){
        types.forEach(type => {
          this.vesselTypes.push(type.data());
        })
        console.log(this.vesselTypes);
      }
    })
    this.db.collection('customer').ref.orderBy('name').get().then(customers => {
      if(!customers.empty){
        customers.forEach(customer => {
          this.customers.push(customer.data());
        })
        console.log(this.customers);
      }
    })
    this.loadingService.setLoadingScreen(false);
  }

  overview(){
    this.router.navigate(['vessels']);
  }

  addVesselType(type: string): void{
    this.vesselType = type;
  }

  canSubmit(): boolean{
    if(this.vesselType != null){
      if(this.vesselLength > 0 && this.customerSelected != null && this.vesselName != null){
        return true;
      }
    }
    return false;
  }

  async saveVessel(){
    let validate = await this.validateVessel()
    if(validate == null){
      if(this.vesselId){
        //Check if IMO is changed
        if(this.vesselId != this.imo){
          this.db.collection('vessel').doc(this.vesselId).delete().then(() => {
            this.db.collection('inspection').ref.where('imo', '==', this.vesselId).get().then(res => {
              if(!res.empty){
                res.forEach(result => {
                  this.db.collection('inspection').doc(result.get('id')).update({
                    imo: this.imo
                  })
                })
              }
            }).then(() => {
              this.saveNewVessel();
            })

          })
        }else{
          let customerId = this.customers.find(x => x.name == this.customerSelected);
          if(customerId === undefined){
            this.toastr.error('The customer does not exist.', 'Validation Error');
            return;
          }
          let typeName = this.vesselTypes.find(x => x.id == this.vesselType);
          if(typeName === undefined){
            this.toastr.error('The boat type does not exist.', 'Validation Error');
            return;
          }
          this.db.collection('vessel').doc(this.vesselId).update({
            imo: this.imo,
            name: this.vesselName,
            type: this.vesselType,
            customer: customerId.id,
            length: this.vesselLength,
            photo: this.photo,
            typeName: typeName.name
          }).then(() => this.router.navigate(['vessels']));
        }
      }else{
        this.saveNewVessel();
      }
    }else{
      this.toastr.error(validate, 'Validation Error');
    }
  }

  async saveNewVessel(){
    let customerId = this.customers.find(x => x.name == this.customerSelected);
    //This will be gone in Phase 3
    if(customerId === undefined){
      this.toastr.error('The customer you entered does not exist.');
      return;
    }
    let typeName = this.vesselTypes.find(x => x.id == this.vesselType).name;
    this.db.collection('vessel').doc(this.imo).set({
      imo: this.imo,
      name: this.vesselName,
      type: this.vesselType,
      customer: customerId,
      length: this.vesselLength,
      photo: this.photo,
      typeName: typeName
    }).then(() => this.router.navigate(['vessels']));
  }

  async validateVessel(){
    let imoMatch = false;
    await this.db.collection('vessel').ref.where('imo', '==', this.imo).get().then(res => {
      if(!res.empty){
        imoMatch = true;
      }
    })
    if(this.vesselName == null || this.vesselName.trim() == ""){
      return "Vessel Name is required";
    }else if(this.vesselLength == null || !this.vesselLength || this.vesselLength <= 0 || isNaN(this.vesselLength)){
      return "Please check the Vessel Length";
    }else if(this.vesselType == null || this.vesselType.trim() == ""){
      return "Vessel Type is required";
    }else if(this.customerSelected == null || this.customerSelected.trim() == ""){
      return "Customer is required";
    }else if(this.imo == null || this.imo.trim() == ""){
      return "IMO is required";
    }else if(imoMatch && !this.vesselId){
      return "IMO already exists";
    }else{
      return null
    }
  }

  uploadPhoto(file: FileList){
    let toastId = this.toastr.info("Please wait while the image is uploaded...", "Uploading!", {
      disableTimeOut: true
    }).toastId;
    const fileToUpload = file.item(0);
    if(fileToUpload.type.split('/')[0] === 'image'){
      const path = `vessel/${new Date().getTime()}_${fileToUpload.name}`;
      let ref = this.afStorage.ref(path);

      let task = this.afStorage.upload(path, fileToUpload).then(val => {
        this.afStorage.ref(path).getDownloadURL().subscribe(data => {
          this.photo = data;
          this.db.collection('vessel').doc(this.imo).update({
            photo: this.photo
          })
        });
      }).then(() => this.toastr.remove(toastId)).catch(() => {
        this.toastr.error("There was a problem uploading the image, please try again.", "Upload Error!");
      });
    }
  }

  selectCustomer(customerName){
    this.customerSelected = customerName;
  }
}
