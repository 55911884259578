<app-header></app-header>
<div class="content">
  <h1 class="overview-title">Vessels</h1>
  <div class="save-button" (click)="navigateToVessel()">
    New Vessel
  </div>
  <div class="table-container">
    <table class="table-case">
      <tr class="title-tr">
        <td>
          <i (click)="filterList('imo', 1)"
            [ngClass]="{'selected': filterSelected('imo', 1)}" class="fas fa-chevron-up up"></i>
          <span>IMO</span>
          <i (click)="filterList('imo', 2)"
            [ngClass]="{'selected': filterSelected('imo', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('name', 1)"
            [ngClass]="{'selected': filterSelected('name', 1)}" class="fas fa-chevron-up up"></i>
          <span>Vessel Name</span>
          <i (click)="filterList('name', 2)"
            [ngClass]="{'selected': filterSelected('name', 2)}" class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('type', 1)"
            [ngClass]="{'selected': filterSelected('type', 1)}" class="fas fa-chevron-up up"></i>
          <span>Type</span>
          <i (click)="filterList('type', 2)"
            [ngClass]="{'selected': filterSelected('type', 2)}" class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <i (click)="filterList('length', 1)"
            [ngClass]="{'selected': filterSelected('length', 1)}" class="fas fa-chevron-up up"></i>
          <span>Length (m)</span>
          <i (click)="filterList('length', 2)"
            [ngClass]="{'selected': filterSelected('length', 2)}"
            class="fas fa-chevron-down down"></i>
        </td>
        <td>
          <span>View Vessel</span>
        </td>
      </tr>
      <tr *ngFor="let vessel of vessels | async" class="content-tr">
        <td>
          <span>{{vessel['imo']}}</span>
        </td>
        <td>
          <span>{{vessel['name']}}</span>
        </td>
        <td>
          <span>{{vessel['typeName']}}</span>
        </td>
        <td>
          <span>{{vessel['length']}}</span>
        </td>
        <td (click)="navigateToVessel(vessel['imo'])" class="icon-td">
          <div class="btn">
            <i class="far fa-file-alt"></i>
            <span>View</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
