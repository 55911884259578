import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InspectionNavService } from './inspection-nav.service';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private loadingService: LoadingService,
    public inspecNav: InspectionNavService) { }
  result = null;
  ngOnInit(): void {
  	this.sectionChecker();
  }

  sectionChecker(): void {
  	this.result = localStorage.getItem('intSection');
  	if(!this.result && this.router.url !== '/login') {
  		this.router.navigate(['/login/']);
  	}
  }

  getLoadingScreen(){
    return this.loadingService.getLoadingScreen();
  }

}
