<div class="container" [ngClass]="{'no-scroll': inspecNav.page != 'interactive'}">
  <router-outlet></router-outlet>
  <div [ngClass]="{'display-loading': getLoadingScreen()}" class="loading-filter">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
