<div class="container">
<div class="header">
<div class="logos">
<div class="main-img">
<img src="assets/images/logo.png">
</div>
<div class="img">
<img src="assets/images/AkzoNobel_wordmark_RGB.png">
</div>
</div>
</div>
<div [hidden]="forgotPwShow" class="login-container">
<div [hidden]="!errMsg" class="row">
<span class="instruction-type">{{errMsg}}</span>
</div>
<div [hidden]="!resetScreen" class="row">
<span class="instruction-type">If the email you specified exists in our system, we've sent a password reset link to it.</span>
</div>
<div class="row">
<input (keydown.enter)="onKeydown($event)" [(ngModel)]="username" placeholder="Username" type="text">
</div>
<div class="row">
<input (keydown.enter)="onKeydown($event)" [(ngModel)]="userpw" placeholder="Password" type="password">
</div>
<div class="row">
<div (click)="forgotPwScreen()" class="btn forgot-type">
<span>Forgotten Password</span>
</div>
<div (click)="login()" class="btn bold-type enter-type">
<span>LOGIN</span>
</div>
</div>
</div>
<div [hidden]="!forgotPwShow" class="login-container">
<div class="row">
<div (click)="forgotPwScreen()" class="btn back-type">
<span>Back</span>
</div>
</div>
<div class="row">
<span class="instruction-type">Enter your account email address and we will send you a link to reset your password.</span>
<input [(ngModel)]="username" placeholder="Email Address" type="text">
</div>
<div class="row">
<div (click)="resetPassword()" class="btn bold-type reset-type">
<span>RESET PASSWORD</span>
</div>
</div>
</div>
</div>
