import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private currentPage: string = "inspection";

  constructor() { }

  set CurrentPage(page: string){
    this.currentPage = page;
  }

  get CurrentPage():string {
    return this.currentPage
  }
}
